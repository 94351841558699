@import 'erpcore/assets/scss/main';

.presentation-prospect-sessions {
    margin: 0 0 $spacing * 3;

    .presentation-administration__section-content-title {
        margin-bottom: 4px;
    }

    &__item {
        display: flex;
        font-size: 13px;
        line-height: 16px;
        margin: 0 0 4px;

        &-info {
            margin-right: 4px;
            color: rgba(getColor(grey), 1);
            font-style: italic;
        }

        &-icon {
            margin-right: 4px;
            width: $spacing * 2;
            height: $spacing * 2;

            .tooltip {
                width: $spacing * 2;
                height: $spacing * 2;
            }

            .svg {
                width: $spacing * 2;
                height: $spacing * 2;
            }

            svg {
                width: $spacing * 2;
                height: $spacing * 2;
                fill: rgba(getColor(apple), 1);
            }

            &[data-attended='0'] {
                svg {
                    fill: rgba(getColor(tomato), 1);
                }
            }
        }

        &-title {
        }
    }
}
