@import 'erpcore/assets/scss/main';

.main--presentation {
    padding: 0 !important;
}

.presentation {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    padding: 0;
    flex-wrap: nowrap;
    flex-direction: column;

    &--admin {
        @include breakpoint(tablet) {
            height: 100%;
            min-height: 100vh;
        }

        @include breakpoint(mobile) {
            // padding-top: 130px;
            // padding-top: 45vh;
        }
        @media (max-width: getBreakpoint(mobile)) and (orientation: landscape) {
            // padding-top: 0;
            // padding-left: 33%;
        }
    }

    &--client[data-phase='in-progress'] {
        @include breakpoint(tablet) {
            height: 100%;
            min-height: 100vh;
        }
    }
}

.presentation-main {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    background: rgba(getColor(black), 1);

    &__device-advice {
        background-color: rgba(getColor(denim), 1);
        border: 1px solid rgba(getColor(white), 0.5);
        padding: $spacing;
        border-radius: 5px;
        display: none;
        margin: $spacing * 4 auto;
        align-items: center;
        max-width: 90%;

        p {
            text-align: center;
            color: white;
        }

        @include breakpoint(mobile) {
            display: flex;
        }
    }

    @include breakpoint(tablet) {
        align-items: stretch;
    }

    @include breakpoint(mobile) {
        flex-wrap: wrap;
    }
}

body {
    .OT_publisher,
    .OT_subscriber {
        .OT_name {
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.OT_mini {
            .OT_name.OT_mode-on,
            .OT_name.OT_mode-auto,
            &:hover .OT_name.OT_mode-auto {
                display: block;
            }
        }

        .OT_bar,
        .OT_name,
        .OT_archiving,
        .OT_archiving-status,
        .OT_archiving-light-box {
            height: 24px;
        }
    }

    .OT_publisher {
        .OT_widget-container {
            &:after {
                content: ' ';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 40px;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
                pointer-events: none;
            }
        }
    }
}
