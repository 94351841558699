@import 'erpcore/assets/scss/main';

.content {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: stretch;
    align-items: stretch;
    justify-content: flex-start;

    @include breakpoint($to: mobile) {
        flex-direction: column;
    }
}
