@import 'erpcore/assets/scss/main';

.presentation-camera-subscribers {
    $_this: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__main {
        position: relative;
        flex: 1 1 100%;
    }

    &__others {
        position: relative;
        flex: 0 0 25%;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
    }

    &__item {
        position: relative;
        flex: 0 0 100%;
        height: 100%;

        #{$_this}__others & {
            flex: 0 0 33%;
        }

        .OT_subscriber {
            position: absolute;
            display: block;
            width: 100% !important;
            height: 100% !important;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;

            video {
                position: absolute;
                display: block;
                width: 100%;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                background: rgba(getColor(black), 1);
            }
        }
    }

}