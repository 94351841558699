:root {
    --buttonColor: 0, 102, 138;
    --primaryColor: 0, 102, 138;
    --midOrange: 236, 119, 61;
    --darkGrey: 21, 21, 21;
    --midGrey: 230, 230, 229;
    --grey: 182, 182, 182;
    --brightGrey: 224, 224, 224;
    --lightGrey: 245, 245, 245;
    --black: 0, 0, 0;
    --white: 255, 255, 255;
    --sun: 240, 195, 86;
    --tomato: 237, 118, 122;
    --sky: 86, 168, 194;
    --apple: 141, 200, 117;
    --lavander: 191, 135, 225;
    --neon: 116, 116, 238;
    --orange: 248, 141, 76;
    --orange: 255, 255, 255;
}

$colors: (
    blue: var(--primaryColor),
    denim: var(--primaryColor),
    mid-orange: var(--midOrange),
    dark-grey: var(--darkGrey),
    mid-grey: var(--midGrey),
    grey: var(--grey),
    bright-grey: var(--brightGrey),
    light-grey: var(--lightGrey),
    black: var(--black),
    white: var(--white),
    sun: var(--sun),
    tomato: var(--tomato),
    sky: var(--sky),
    apple: var(--apple),
    lavander: var(--lavander),
    neon: var(--neon),
    orange: var(--orange),
    default: var(--default)
);
