@import 'erpcore/assets/scss/main';

.presentation-screenshare {
    position: relative;
    flex: 1 1 100%;
    height: 100%;
    padding: 0;
    background-color: rgba(getColor(black), 1);
    background-image: linear-gradient(
        131deg,
        #000000 10%,
        #303030 10%,
        #303030 50%,
        #000000 50%,
        #000000 60%,
        #303030 60%,
        #303030 100%
    );
    background-size: 6.63px 7.62px;
    overflow: hidden;
    min-height:100vh;

    @media (max-width: 1030px) {
        min-height: 100vh;
    }

    @include breakpoint(mobile) {
        width: 100%;
        height: auto;
        min-height: auto;
        min-height: unset;
        order: 0;
    }

    &__content {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;

        @include breakpoint(mobile) {
            position: relative;
            bottom: auto;
            top: auto;
            left: auto;
            right: auto;
        }
    }

    &__controls {
        position: absolute;
        bottom: $spacing * 3;
        right: $spacing * 3;
        display: block;
    }

    &__video {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 0 $spacing;
        background-color: rgba(getColor(black), 1);
        background-image: linear-gradient(
            131deg,
            #000000 10%,
            #303030 10%,
            #303030 50%,
            #000000 50%,
            #000000 60%,
            #303030 60%,
            #303030 100%
        );
        background-size: 6.63px 7.62px;

        @include breakpoint(mobile) {
            height: auto;
        }

        &-presentation {
            width: 100%;
            height: 100%;

            > div {
                width: 100%;
                height: 100%;
            }
        }

        .OT_subscriber {
            @include breakpoint(mobile) {
                height: auto !important;

                .OT_widget-container {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: auto !important;
                    bottom: auto;
                    top: auto;
                    left: auto;
                    right: auto;
                }
            }
        }

        .OT_video-poster {
            display: none !important;
        }

        video.OT_video-element {
            display: block !important;
        }

        video {
            background-color: rgba(getColor(black), 1);
            background-image: linear-gradient(
                131deg,
                #000000 10%,
                #303030 10%,
                #303030 50%,
                #000000 50%,
                #000000 60%,
                #303030 60%,
                #303030 100%
            );
            background-size: 6.63px 7.62px;

            @include breakpoint(mobile) {
                position: relative !important;
                display: block !important;
                width: 100% !important;
                height: auto !important;
                bottom: auto;
                top: auto;
                left: auto;
                right: auto;
            }
        }
    }
}
