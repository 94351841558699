@import 'erpcore/assets/scss/main';

.presentation-sidebar {
    $_this: &;
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: column;
    flex: 0 0 24%;
    max-width: 462px;
    min-width: 440px;
    background: rgba(getColor(white), 1);
    padding: 0;
    overflow: hidden;
    transition: flex 0.3s, opacity 0.1s getTransitionEasing('ease-out-cubic') 0.2s;
    z-index: 1000;

    @include breakpoint($from: tablet) {
        height: 100%;
    }

    @include breakpoint(desktop) {
        min-width: 300px;
    }

    @include breakpoint(tablet) {
        min-width: 300px;
    }

    &--client {
        color: rgba(getColor(white), 1);
        background-color: rgba(getColor(black), 1);

        @include breakpoint(tablet) {
            flex: 0 0 30%;
        }

        @include breakpoint(small) {
            flex: 0 0 180px;
        }

        &[data-opened='0'] {
            @include breakpoint($from: mobile) {
                flex: 0 0 0px;
                min-width: 0px;
                opacity: 0;
                pointer-events: none;
                overflow: hidden;
                transition: flex 0.3s 0.1s, opacity 0.1s getTransitionEasing('ease-in-cubic');
            }
        }
    }

    &--admin {
        @include breakpoint(tablet) {
            flex: 0 0 30%;
        }

        @include breakpoint(small) {
            flex: 0 0 100%;
        }

        @include breakpoint(mobile) {
            .presentation-chat__messages {
                padding: 24px 12px 12px 12px;
                margin-top: 10px;
                border-top: 1px solid rgba(getColor(mid-grey), 1);
            }
        }
    }

    @include breakpoint(mobile) {
        flex: 0 0 100%;
        width: 100%;
        min-width: 100% !important;
        flex-direction: row;
        flex-wrap: nowrap;
        z-index: 9999;

        .presentation--client[data-phase='in-progress'] & {
            order: 1;
        }
    }

    &__call {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: percentage(400 / 462);
        background: rgba(getColor(black), 1);
        transition: getTransition();

        > div:not(.presentation-sidebar__call-video-publisher) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        @include breakpoint(mobile) {
            display: none !important;
        }

        .OT_subscriber {
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;

            .presentation--admin & {
                @include breakpoint(mobile) {
                    position: absolute;
                    width: 50% !important;
                    height: 100% !important;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    padding-bottom: 0;
                }
                @media (max-width: getBreakpoint(mobile)) and (orientation: landscape) {
                    position: absolute;
                    width: 100% !important;
                    height: 50% !important;
                    top: 0;
                    left: 0;
                    padding-bottom: 0;
                }
            }
        }

        &-video-publisher {
            position: absolute;
            width: 120px;
            height: 90px;
            top: auto;
            bottom: $spacing * 3;
            left: auto;
            right: $spacing * 3;
            // border: 1px solid rgba(#fff, 0.25);
            border: 1px solid #404040;
            background: rgba(getColor(black), 1);
            z-index: 2;

            .presentation--admin & {
                @include breakpoint(mobile) {
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    right: 0;
                    padding: 0;
                }
                @media (max-width: getBreakpoint(mobile)) and (orientation: landscape) {
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    top: 50%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 0;
                }
            }

            .presentation--admin & {
                @include breakpoint(mobile) {
                    // display: none;
                }
            }

            .presentation--admin.presentation--one-to-many & {
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        &-controls {
            position: absolute;
            width: 120px;
            height: auto;
            top: auto;
            bottom: 0;
            left: auto;
            right: $spacing * 3;
            z-index: 3;
        }
    }

    &__content {
        flex: 0 1 auto;
        overflow: auto;
        height: 100%;
        max-height: 100%;
        padding: $spacing * 3 $spacing * 3 $spacing;
        transition: getTransition();
        pointer-events: auto;

        @include breakpoint(tablet) {
            padding: $spacing * 2;
        }

        @include breakpoint(mobile) {
            flex: 0 0 100%;
        }

        @include breakpoint(small) {
            padding-right: $spacing;
        }

        .presentation--admin & {
            @include breakpoint(mobile) {
                // display: none !important;
            }
        }

        #{$_this}--client & {
            background-color: rgba(getColor(black), 1);
            background-image: url(~erpcore/assets/images/abstract-bg-dark.jpg);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: cover;
        }
    }

    &__info {
        $_info: &;
        position: relative;
        display: block;
        width: 100%;

        &-row {
            margin: 0 0 $spacing * 4;
        }

        &-title {
            margin: 0 0 $spacing;
            font: $font-label;
            line-height: 1;
            text-transform: uppercase;
            color: #858585;
            letter-spacing: 1px;
        }

        &-label-large {
            margin: 0 0 3px;
            font: $font-body;
            font-weight: 700;
            line-height: 1;

            @include breakpoint(small) {
                font-size: 14px;
            }
        }

        &-label {
            margin: 0;
            font: $font-label;
            font-size: 12px;
            line-height: 18px;

            @include breakpoint(mobile) {
                word-break: break-word;
            }

            @include breakpoint(small) {
                font-size: 10px;
            }
        }

        &-img {
            display: block;
            width: 145px;
            max-width: 100%;
            height: auto;
            margin: $spacing * 5 auto 0;

            @include breakpoint(mobile) {
                margin: 40px 0;
            }
        }
    }
}
