@import 'erpcore/assets/scss/main';

.progress-bar {
    width: 100%;
    min-width: 100px;
    height: 10px;
    margin: 0;
    background: #E5EFF3;
    background: rgba(getColor(denim), 0.1);
    overflow: hidden;

    &__line {
        height: 100%;
        background: rgba(getColor(denim), 1);
        transition: getTransition('slide-slow');
    }
}
