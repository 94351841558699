@import 'erpcore/assets/scss/main';

.form {
    $this: &;
    width: 100%;
    position: relative;
    margin: 0 0 ($spacing * 3) 0;

    &:last-child {
        margin-bottom: 0px;
    }

    &__column {
        flex: 1;
        width: 100%;
        border: none;
        padding: 0px;
        margin: 0px;
    }

    &__section {
        &-title {
            font: $font-2;
            margin: ($spacing * 2) 0px ($spacing * 3) 0px;

            &:first-child {
                margin-top: 0px;
            }
        }

        &-title-small {
            font: $font-3;
            margin: ($spacing * 2) 0 $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &-subtitle {
            display: block;
            width: 100%;
            font: $font-body;
            margin: $spacing 0 0 0;
        }
    }

    &__row {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 ($spacing * 2) 0;

        @include breakpoint($to: tablet) {
            display: block;
        }

        &--vertical-center {
            align-items: center;
        }

        &:last-child,
        &:empty {
            margin-bottom: 0px;
        }

        &-column {
            width: 50%;
            flex: 0 0 50%;
            padding-left: $spacing;
            padding-right: $spacing;

            @include breakpoint($to: tablet) {
                width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $spacing * 2;
            }

            &:first-child,
            &:empty {
                padding-left: 0;
            }

            &:last-child,
            &:empty {
                padding-right: 0;

                @include breakpoint($to: tablet) {
                    margin-bottom: 0;
                }
            }

            &-responsive {
                width: auto;
                display: block;

                @include breakpoint($to: tablet) {
                    margin-bottom: $spacing * 2;
                }

                &:last-child {
                    @include breakpoint($to: tablet) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__columns,
    &--with-columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 3;

        &:last-child {
            margin-bottom: 0px;
        }

        @include breakpoint($to: tablet) {
            flex-direction: column;
        }

        #{$this}__column {
            flex-basis: 50%;
            width: 50%;
            margin-right: $spacing * 8;

            &:last-child {
                margin-right: 0px;

                @include breakpoint($to: tablet) {
                    margin-bottom: 0px;
                }
            }

            @include breakpoint($to: tablet) {
                width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $spacing * 2;
                margin-right: 0px;
            }
        }
    }

    &__help-text {
        text-align: right;

        @include breakpoint($to: tablet) {
            text-align: left;
        }
    }

    &--inline {
        display: inline-block;
        width: auto;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: $spacing;
        }
    }

    &__row--start {
        justify-content: flex-start;
    }
}

// View Pages
.view-only {
    .input {
        &__label {
            left: 0px;
            transform: translate(0px, -11px);
            padding-left: 0px;
        }

        input,
        &__field {
            background-color: transparent;
            border: none;
            padding: $spacing 0px;
            height: auto;
            min-height: auto;
            font: $font-form;
        }

        &--disabled {
            opacity: 1;
            min-height: $spacing * 5;
        }
    }

    .react-select--is-disabled {
        .react-select__control {
            background-color: transparent;
            border: none;
            padding: 0px;
        }

        .react-select__indicators {
            display: none;
        }
    }

    .react-phone-number-input {
        &.input__field {
            padding: 0px;
        }

        &__country--native {
            display: none;
        }
    }
}
