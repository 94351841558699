@import 'erpcore/assets/scss/main';

.mobile-quick-nav {
    $_this: &;
    display: flex;
    position: fixed;
    flex-wrap: nowrap;
    align-items: center;
    top: 0;
    left: 68px;
    right: 0;
    height: 44px;
    padding: 0 $spacing * 2 0 0;
    overflow: hidden;
    color: #fff;
    z-index: 99999;

    .sidebar--show-deck &,
    .sidebar--show-deck ~ .main & {
        display: none;
    }

    @include breakpoint($from: mobile) {
        display: none !important;
    }

    &__title {
        flex: 1 1 100%;
        margin: 0;
        font-size: 16px;
        line-height: 16px;
    }

    &__next {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        padding-left: 4px;

        &-label {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            color: rgba(getColor(denim), 1) !important;
        }

        &-icon {
            width: $spacing * 2;
            height: $spacing * 2;

            svg {
                width: $spacing * 2;
                height: $spacing * 2;
                fill: rgba(getColor(denim), 1) !important;
            }
        }
    }
}
