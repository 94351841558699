@import 'erpcore/assets/scss/main';

.presentation-prospect-counter {
    display: block;
    width: 100%;
    margin: $spacing * 3 0 0;
    $_this: &;

    $_client_color: getColor(denim);
    $_admin_color: getColor(dark-grey);

    &__title {
        margin: 0;
        font-size: 10px;
        color: rgba(getColor(grey), 1);
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__count {
        position: relative;
        flex: 0 0 $spacing * 5;
        width: $spacing * 5;
        height: $spacing * 5;
        margin: 6px 0;
        font-size: 20px;
        font-weight: 700;
        line-height: $spacing * 5;
        background: rgba($_admin_color, 1);
        color: #fff;
        border-radius: 50%;
        text-align: center;
        z-index: 2;

        #{$_this}--client & {
            background: rgba($_client_color, 1);
        }
    }

    &__notifications {
        flex: 0 1 auto;

        &-item {
            $_notification: &;
            display: flex;
            align-items: center;
            background: rgba($_admin_color, 0.15);
            color: rgba($_admin_color, 1);
            border-radius: 3px;
            margin: 2px 0;
            margin-left: -($spacing * 2);
            overflow: hidden;
            transition: 0.5s getTransitionEasing('ease-out-cubic');

            &-icon {
                flex: 0 0 $spacing * 3;
                width: $spacing * 3;
                height: $spacing * 3;
                margin-right: 4px;

                svg {
                    width: $spacing * 3;
                    height: $spacing * 3;

                    .userGroupFill {
                        stroke: rgba($_admin_color, 1);

                        #{$_this}--client & {
                            stroke: rgba($_client_color, 1);
                        }
                    }
                }
            }

            #{$_this}--client & {
                background: rgba($_client_color, 0.15);
                color: rgba($_client_color, 1);
            }

            &--disconnected {
                background: rgba(getColor(tomato), 0.15) !important;
                color: rgba(getColor(tomato), 1) !important;

                #{$_notification}-icon {
                    svg {
                        .userGroupFill {
                            stroke: rgba(getColor(tomato), 1);
                        }
                    }
                }
            }

            &[data-test="0"] {
                opacity: 0;
                flex: 0 0 0;
                height: 0;
                margin: 0;
                margin-left: -($spacing * 4);
                padding: 0;
                pointer-events: none;
            }

            &[data-test="1"] {
                flex: 0 1 auto;
                opacity: 1;
                height: 24px;
                padding: 0 10px 0 $spacing * 3;
                pointer-events: auto;
            }

            &-text {
                flex: 1 1 100%;
                font-size: 12px;
                line-height: 20px;
                white-space: nowrap;
            }
        }
    }
}
