@import 'erpcore/assets/scss/main';

.page-header {
    $this: &;
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    min-height: $spacing * 11;
    align-items: center;
    justify-content: space-between;
    min-height: $spacing * 10;
    padding: ($spacing * 3) ($spacing * 6);
    border-bottom: 1px solid rgba(getColor(mid-grey), 1);
    background-color: #ffffff;
    z-index: 1000;

    @include breakpoint(tablet) {
        padding: $spacing *2 $spacing * 3;
    }

    @include breakpoint(mobile) {
        display: none !important;
        position: relative;
        min-height: auto;
        padding: $spacing $spacing * 2;
        flex-wrap: wrap;
    }

    &__title {
        flex: 1;
        font: $font-2;
        margin: 0 0 $spacing 0;

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
            line-height: 1.3;
        }
    }

    &__subtitle {
        margin-bottom: 0;
    }

    &__content {
        margin-right: auto;

        @include breakpoint(mobile) {
            display: none;
            order: 1;
            width: 100%;
        }
    }

    &__back {
        height: 100%;
        padding-top: 0;
        margin-left: -($spacing * 2);
        display: flex;
        align-items: center;

        @include breakpoint($from: tablet) {
            position: absolute;
            left: -($spacing * 7);
            bottom: 0;
            margin-left: 0;
            z-index: 10;
        }

        &-button {
            background-color: transparent;
            border: none;
            color: rgba(getColor(black), 1);
            fill: rgba(getColor(black), 1);

            &:hover {
                background-color: transparent;
                border: none;
                color: rgba(getColor(black), 1);
                fill: rgba(getColor(black), 1);
            }

            .button__icon {
                fill: rgba(getColor(black), 1);

                svg {
                    fill: rgba(getColor(black), 1);
                }
            }
        }
    }

    &__favorite {
        width: $spacing * 3;
        height: $spacing * 3;
        display: inline-block;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: 0;
        margin: 0 auto 0 ($spacing * 2);
        border: none;
        background-color: transparent;
        transition: getTransition();

        .svg {
            display: block;
            width: 100%;
            height: auto;

            svg {
                display: block;
                fill: getColor(silver-light-grey);
                transition: getTransition();
            }
        }

        &:hover {
            svg {
                fill: rgba(getColor(sun), 1);
            }
        }

        &:focus {
            svg {
                fill: rgba(getColor(sun), 1);
            }
        }
    }

    &__actions {
        display: flex;

        @include breakpoint(mobile) {
            order: 0;
            margin: 0 0 $spacing;
        }

        &-dots {
            display: none !important;
            // display: flex;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            align-items: center;
            align-content: center;
            justify-content: center;
            transition: getTransition();
            background-color: transparent;

            svg {
                width: $spacing / 2;
                margin: 0 auto;
                display: block;
                fill: getColor(silver-grey);
            }

            @include breakpoint($from: tablet) {
                display: none;
            }
        }

        &-items {
            display: flex;

            /*
            @include breakpoint(mobile) {
                height: $spacing * 5;
                background: rgba(getColor(white), 1);
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                bottom: 0;
                flex-wrap: nowrap;
                position: absolute;
                align-items: center;
                align-content: center;
                list-style-type: none;
                transition: getTransition();
                justify-content: flex-start;
            }
            */

            @include breakpoint(mobile) {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                width: 100%;
                transform: none;
            }
        }
    }
}
