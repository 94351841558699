@import 'erpcore/assets/scss/main';

.presentation-participants {
    position: relative;
    border-bottom: 1px solid rgba(getColor(mid-grey), 1);
    padding-bottom: $spacing;
    margin-bottom: $spacing * 2;
    $root: &;

    &__title {
        display: block;
        text-transform: uppercase;
        font-weight: lighter;
        color: rgba(getColor(grey), 1);
        margin-top: $spacing * 3;

        &:first-child {
            margin-top: 0;
        }
    }

    &__participant {
        position: relative;
        $message: &;
        font-size: 13px;
        line-height: 16px;
        margin: $spacing * 2 0;

        &:first-child {
            margin-top: 0;
        }

        &-author {
            font-weight: bold;
        }
        &-action {
            position: absolute;
            right: 0;
            top: -4px;

            .button--small {
                margin-right: 0;
            }
        }
    }
}
