@import 'erpcore/assets/scss/main';

.presentation-notes {
    .text-editor {
        border: 1px solid rgba(getColor(grey), 0.25);
        margin-bottom: $spacing * 2;

        &__toolbar {
            border-bottom: 1px solid rgba(getColor(grey), 0.25);
        }
    }
}
