@import 'erpcore/assets/scss/main';

.presentation-forbidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__logo {
        width: 140px;
        margin: 0 auto $spacing * 2;

        img {
            width: 140px;
            height: auto;
        }
    }

    &__content {
        width: 444px;
        max-width: 100%;
        margin: 0 auto;
    }
}
