@import 'erpcore/assets/scss/main';

.presentation-intro {
    $_this: &;
    width: 100%;
    max-width: 1200px;
    margin: $spacing * 8 auto 0;
    flex: 0 1 auto;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    background: rgba(getColor(black), 1);

    @media (min-width: 1201px) and (max-width: 1360px) {
        margin: calc((100% - 1200px) / 2) auto 0;
    }

    @media (max-width: 1200px) {
        margin: 0;
    }

    @media (min-width: 1250px) and (max-height: 750px) {
        margin-top: $spacing * 5;
        flex: 1 1 auto;
    }

    @media (min-width: 1250px) and (min-height: 751px) {
        min-height: 614px;
    }

    @media (max-width: 1250px) {
        flex: 0 1 100%;
    }

    @include breakpoint(mobile) {
        height: 100%;
        min-height: 65vh;
        max-height: none;
    }

    &__logo {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: absolute;
        width: 100%;
        height: 75px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1001;
        overflow: hidden;
        // background: linear-gradient(0deg, rgba(getColor(tomato),1) 62%, rgba(getColor(tomato),0) 100%);
        background: linear-gradient(
            0deg,
            rgba(getColor(light-grey), 1) 62%,
            rgba(getColor(light-grey), 0) 100%
        );

        svg {
            position: relative;
            margin: 0 0 $spacing * 2 $spacing * 4;
            width: 58px;
            height: auto;
            z-index: 1003;
            pointer-events: auto;
        }
    }

    &__sidebar {
        position: sticky;
        left: 0;
        display: flex;
        flex: 0 0 35%;
        flex-direction: column;
        max-width: 570px;
        min-width: 360px;
        height: 100%;
        padding: 0;
        background: rgba(getColor(light-grey), 1);
        overflow: hidden;
        transition: flex 0.3s, opacity 0.1s getTransitionEasing('ease-out-cubic') 0.2s;
        z-index: 1000;

        @include breakpoint(tablet) {
            min-width: auto;
        }

        @include breakpoint(mobile) {
            flex: 0 0 100%;
            min-width: 100%;
            max-width: 100%;
        }

        .presentation--client[data-phase='intro'] & {
            overflow: auto;
        }

        &-tabs {
            display: flex;

            &:focus {
                outline: unset !important;
            }

            &-tab {
                flex: 1;
                border: 0;
                padding: $spacing * 2 0;
                font: $font-4;
                color: rgba(getColor(denim), 1);
                background-color: rgba(getColor(denim), 0.08);
                position: relative;
                cursor: pointer;

                &-unread-messages {
                    background-color: white;
                    display: inline-block;
                    padding: 0 $spacing;
                    border-radius: 50%;
                    margin-left: $spacing / 1.5;
                }

                &:focus {
                    outline: unset !important;
                }

                &--active,
                &:hover {
                    background-color: rgba(getColor(light-grey), 1);
                    &:after {
                        opacity: 0 !important;
                    }
                }
            }
        }

        &-data {
            position: relative;
            padding: $spacing * 3 $spacing * 4 $spacing * 2;
            margin: 0;

            &--full-height {
                height: 100%;
            }

            @include breakpoint(tablet) {
                padding: $spacing * 2;
            }

            @include breakpoint(small) {
                padding-right: $spacing;
            }

            &:before {
                content: ' ';
                position: absolute;
                left: $spacing * 4;
                right: $spacing * 4;
                bottom: 0;
                height: 1px;
                border-bottom: 1px solid rgba(getColor(bright-grey), 1);
            }

            .presentation--client &,
            &--no-border {
                &:before {
                    display: none;
                }
            }

            &-item {
                display: flex;
                align-items: flex-start;
                margin: 0 0 $spacing * 2;

                .presentation--client & {
                    flex-wrap: wrap;
                    margin: 0 0 $spacing * 4;

                    @include breakpoint(tablet) {
                        margin: 0 0 $spacing * 2;
                    }
                }

                @include breakpoint(tablet) {
                    flex-wrap: wrap;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &-title {
                    flex: 0 0 ($spacing * 20);
                    font-size: 12px;
                    color: rgba(getColor(grey), 1);
                    padding: 0 $spacing 0 0;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;

                    .presentation--client & {
                        flex: 0 0 100%;
                        margin: 0 0 4px;
                    }

                    @include breakpoint(tablet) {
                        flex: 0 0 100%;
                        margin: 0 0 4px;
                    }

                    @include breakpoint(small) {
                        font-size: 10px;
                    }
                }

                &-value {
                    flex: 1 1 100%;
                    font: $font-4;
                    font-size: 16px;

                    .presentation--client & {
                        flex: 0 0 100%;
                        font-size: 18px;

                        @include breakpoint(tablet) {
                            font-size: 16px;
                        }

                        @include breakpoint(small) {
                            font-size: 14px;
                        }
                    }

                    @include breakpoint(tablet) {
                        flex: 0 0 100%;
                    }

                    @include breakpoint(small) {
                        font-size: 14px;
                    }
                }

                &-user {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    &-logo {
                        flex: 0 0 54px;
                        margin-right: $spacing;
                        border-radius: 50%;
                        overflow: hidden;

                        @include breakpoint(tablet) {
                            flex: 0 0 36px;
                        }

                        @include breakpoint(small) {
                            flex: 0 0 24px;
                        }

                        &-image {
                            display: block;
                            width: 100%;
                            height: auto;
                            margin: 0;
                        }
                    }

                    &-data {
                        flex: 1 1 100%;

                        &-title {
                            margin: 0 0 $spacing;
                            font: $font-4;
                            font-size: 18px;

                            @include breakpoint(tablet) {
                                margin: 0 0 4px;
                                font-size: 14px;
                            }
                        }

                        &-email,
                        &-telephone {
                            margin: 0 0 4px;
                            font: $font-label;
                            font-size: 12px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            @include breakpoint(tablet) {
                                word-break: break-word;
                            }

                            @include breakpoint(small) {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }

        &-call {
            padding: $spacing * 2 $spacing * 4;
            overflow: auto;

            @include breakpoint(tablet) {
                padding: $spacing * 2;
            }

            &-title {
                font: $font-4;
                margin: 0 0 $spacing * 2;
            }

            &-list {
                display: block;
                width: 100%;
                max-width: 420px;
                margin: 0 0 $spacing * 2;
                padding: 0;
                counter-reset: introListCounter;

                li {
                    position: relative;
                    display: block;
                    margin: 0 0 $spacing * 2;
                    padding: 0 0 0 $spacing * 4;
                    font: $font-label;
                    font-size: 12px;
                    line-height: 16px;

                    @include breakpoint(mobile) {
                        font-size: 10px;
                        line-height: 1.3;
                        padding-left: $spacing * 3;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:before {
                        counter-increment: introListCounter;
                        content: counter(introListCounter);
                        position: absolute;
                        top: -2px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        font-size: 10px;
                        line-height: 21px;
                        font-weight: 700;
                        text-align: center;
                        background: rgba(getColor(denim), 1);
                        color: #fff;
                        border-radius: 50%;
                    }
                }

                .button--link {
                    display: inline;
                    font: $font-label;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    height: 16px;
                    vertical-align: baseline;
                    margin: 0;
                }
            }
        }
    }

    &__call {
        position: relative;
        flex: 1 1 100%;
        height: 100%;
        background: rgba(getColor(mid-grey), 1);
        padding: 0;
        overflow: hidden;

        &-cameras {
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            > div:not(.presentation-intro__call-cameras-publisher) {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            &-publisher {
                position: absolute;
                width: 184px;
                height: 136px;
                top: auto;
                bottom: $spacing * 4;
                left: auto;
                right: $spacing * 4;
                // border: 1px solid rgba(#fff, 0.25);
                border: 1px solid #404040;
                background: rgba(getColor(black), 1);
                z-index: 2;

                .OT_publisher {
                    //
                }

                .presentation--one-to-one & {
                    @include breakpoint(mobile) {
                        width: 140px;
                        height: 102px;
                        bottom: $spacing;
                        right: $spacing;
                    }
                }

                .presentation--one-to-many & {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                }
            }

            &-subscriber {
                position: absolute;
                display: block;
                width: 100%;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;

                .OT_subscriber {
                    position: absolute;
                    display: block;
                    width: 100% !important;
                    height: 100% !important;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                }

                video {
                    position: absolute;
                    display: block;
                    width: 100%;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: rgba(getColor(black), 1);
                }
            }
        }
    }

    &__project {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        overflow: auto;
    }

    &__projects {
        position: absolute;
        display: block;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: $spacing * 4 $spacing * 4 182px;
        overflow: auto;

        @include breakpoint(mobile) {
            padding: $spacing;
        }

        &-title {
            font-size: 12px;
            color: rgba(getColor(grey), 1);
            margin: 0 0 $spacing * 5;
            text-transform: uppercase;
            letter-spacing: 0.5px;

            @include breakpoint(mobile) {
                margin: 0 0 $spacing * 3;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

            &-item {
                flex: 0 0 45%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 0 $spacing * 8;

                // for grid of two
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-child(odd):nth-last-child(2) {
                    margin-bottom: 0;
                }

                @include breakpoint(mobile) {
                    flex: 0 0 100%;
                    margin: 0 0 $spacing * 3 !important;
                }

                &-logo {
                    flex: 0 0 45%;
                    width: 100%;
                    max-width: 100px;
                    margin-right: 10%;

                    @include breakpoint(mobile) {
                        flex: 0 0 $spacing * 3;
                        margin-right: 5%;
                    }

                    &-image {
                        display: block;
                        width: 100%;
                        height: auto;
                        margin: 0;
                    }
                }

                &-data {
                    flex: 1 1 100%;

                    &-title {
                        margin: 0 0 4px;
                        font-size: 18px;
                        line-height: 1;

                        @include breakpoint(mobile) {
                            font-size: 14px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &-location {
                        font: $font-label;

                        @include breakpoint(mobile) {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    &__footer-logo {
        width: 100%;
        max-width: 1200px;
        margin: $spacing * 2 auto $spacing * 2;

        @media (max-width: 1200px) {
            padding-left: $spacing * 2;
        }

        @include breakpoint($to: mobile) {
            padding-bottom: $spacing * 1.5;
        }

        img {
            position: relative;
            margin: 0;
            width: 58px;
            height: auto;
        }
    }
}
