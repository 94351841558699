@import 'erpcore/assets/scss/main';

.background {
    @each $colorName, $colorValue in $colors {
        &--#{$colorName} {
            @if (type-of($colorValue) == string and str-index($colorValue, 'linear-gradient(')) {
                background-image: $colorValue;
            } @else {
                background-color: rgba($colorValue, 1);
            }
        }
    }
}

.color {
    @each $colorName, $colorValue in $colors {
        &--#{$colorName} {
            color: rgba($colorValue, 1);
            @if (type-of($colorValue) == string and str-index($colorValue, 'linear-gradient(')) {
                background: rgba($colorValue, 1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
