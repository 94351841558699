@import 'erpcore/assets/scss/main';

.presentation-header {
    $_this: &;
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    background: rgba(getColor(white), 1);
    box-shadow: 0px 1px 10px rgba(#0f141c, 0.15);
    color: rgba(getColor(denim), 1);
    z-index: 1001;
    overflow: hidden;
    transition: getTransition();
    height: auto;
    padding: $spacing * 2 $spacing * 6 $spacing * 2 $spacing * 3;
    pointer-events: auto;

    @include breakpoint(mobile) {
        position: relative;
        flex-direction: column;
        box-shadow: none;
        padding: $spacing * 2 $spacing * 3 0;
    }

    // layout

    &__info {
        position: relative;
        flex: 0 0 336px;
        margin: 0;
        padding: 0;
        background-image: none;
        background-color: transparent;
        vertical-align: middle;
        user-select: none;
        touch-action: manipulation;
        text-align: left;
        color: rgba(getColor(denim), 1);
        border: none;
        border-radius: 0;
        outline: none;
        text-transform: none;
        text-decoration: none;

        @include breakpoint(tablet) {
            flex: 0 0 auto;
            padding-right: $spacing * 3;
        }

        @include breakpoint(mobile) {
            flex: 0 1 100%;
            margin: 0 0 $spacing * 2;
            cursor: pointer;
        }
    }

    &__center {
        flex: 1 1 100%;
        display: flex;
        justify-content: space-between;

        @include breakpoint(mobile) {
            flex: 0 1 100%;
            margin: 0;
            flex-direction: column;
        }

        #{$_this}[data-mobile-content-opened="0"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        &-col {
            flex: 0 0 45%;

            @include breakpoint(tablet) {
                flex: 0 0 47%;
            }

            @include breakpoint(mobile) {
                flex: 0 1 100%;
                margin: 0 0 $spacing * 2;
            }
        }
    }

    &__screenshare {
        flex: 0 0 100%;
        margin: 0 0 $spacing * 2;

        @include breakpoint($from: mobile) {
            display: none;
        }

        @include breakpoint(mobile) {
            flex: 0 1 100%;
        }

        #{$_this}[data-mobile-content-opened="0"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    &__time {
        flex: 0 0 auto;
        text-align: left;
        padding-left: $spacing * 3;

        @include breakpoint(mobile) {
            flex: 0 1 100%;
            margin: 0 0 $spacing * 2;
            padding-left: 0;
        }

        #{$_this}[data-mobile-content-opened="0"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    &__end {
        flex: 0 0 150px;
        text-align: right;
        align-self: center;

        @include breakpoint(tablet) {
            flex: 0 0 auto;
            padding-left: $spacing * 3;
        }

        @include breakpoint(mobile) {
            flex: 0 0 100%;
            margin: 0 0 $spacing * 2;
            padding-left: 0;
            text-align: left;
            align-self: flex-start;
        }

        #{$_this}[data-mobile-content-opened="0"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        .button {
            font-size: 12px;
            line-height: 18px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // components

    &__toggle {
        display: none;
        position: absolute;
        top: 6px;
        right: 0;
        width: $spacing * 3;
        height: $spacing * 3;
        transform: rotate(90deg);
        transform-origin: center;
        transition: getTransition();

        @include breakpoint(mobile) {
            display: block;
        }

        #{$_this}[data-mobile-content-opened="0"] & {
            transform: rotate(-90deg);
        }

        svg {
            width: $spacing * 3;
            height: $spacing * 3;
            fill: rgba(getColor(grey), 1);
        }
    }

    &__label {
        display: block;
        margin: 0 0 4px;
        font: $font-5;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
    }

    &__title {
        display: block;
        font: $font-4;
        font-size: 17px;
    }

    &__completion {
        display: flex;
        align-items: center;

        &-value {
            flex: 1 1 auto;
            font: $font-4;
            white-space: nowrap;
            margin: 0 $spacing 0 0;
        }

        &-bar {
            flex: 1 1 100%;
        }
    }
}
