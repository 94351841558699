@import 'erpcore/assets/scss/main';

.multiday-picker {
    width: 100%;
    border-radius: 2px;
    min-height: 40px;
    position: relative;
    z-index: 4;
    &__tags {
        margin: 0;
        width: auto;
        position: relative;
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-top: 2px;
        padding-inline-start: 20px;
        padding-inline-end: 40px;
        max-width: 100%;
        flex-wrap: wrap;
        padding: $spacing $spacing * 6 0 $spacing * 2;
        &__item {
            width: auto;
            display: inline-flex;
            flex-wrap: nowrap;
            font: $font-label;
            border-radius: 3px;
            flex-direction: row;
            align-items: center;
            align-content: center;
            margin-right: 8px;
            justify-content: flex-start;
            color: #848692;
            box-shadow: unset;
            padding: 2px 8px 2px 16px;
            background-color: #eeeef3;
            border-radius: 18px;
            border: 0;
            margin-bottom: $spacing;
        }
    }
    .input {
        height: 100%;
        min-height: 40px;
        &--active {
            .DateInput_input {
                color: rgba(getColor(black), 1);
                background-color:rgba(getColor(white), 1);
                padding: 0 ($spacing * 6) 0 ($spacing * 2);
                font-weight: normal;
                -webkit-appearance: none;
                height: 100%;
                font: $font-form;
            }
        }
        &__label {
            z-index: 11;
        }
    }

    .SingleDatePickerInput {
        display: inline-block;
        background-color: transparent;
        &__block {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .SingleDatePicker {
        position: relative;
        display: inline-block;
        &__block {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
        &__picker {
            z-index: 10;
            background-color: transparent;
            position: absolute;
            &__portal {
                background-color: rgba(0, 0, 0, 0.3);
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    .CalendarDay {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        &__blocked_out_of_range {
            background-color: #e4e7e7 !important;
            opacity: 0.5;
            border-radius: 50%;
        }
        &:active {
            outline: 0;
        }
        &__defaultCursor {
            cursor: default;
        }
        &__default {
            color: #484848;
            background: #fff;
            &:hover {
                background: #e4e7e7;
                color: inherit;
                border-radius: 50%;
            }
        }
        &__hovered_offset {
            background: #f4f5f5;
            border: 1px double #e4e7e7;
            color: inherit;
        }
        &__outside {
            border: 0;
            background: #fff;
            color: #484848;
            &:hover {
                border: 0;
            }
        }
        &__blocked {
            &_minimum_nights {
                &:active,
                &:hover {
                    background: #fff;
                    color: #cacccd;
                }
            }
        }
        &__highlighted_calendar {
            background-color: getColor(bordo);
            color: white;
            border-radius: 50%;
            &:hover,
            &:active {
                background-color: getColor('bordo-hover');
            }
        }
        &__selected_span {
            background: #66e2da;
            border: 1px double #33dacd;
            color: #fff;
            &:active,
            &:hover {
                background: #33dacd;
                border: 1px double #33dacd;
                color: #fff;
            }
        }
        &__selected {
            background: #00a699;
            border: 1px double #00a699;
            color: #fff;
            &:active,
            &:hover {
                background: #00a699;
                border: 1px double #00a699;
                color: #fff;
            }
        }
        &__hovered_span {
            background: #b2f1ec;
            border: 1px double #80e8e0;
            color: #007a87;
            &:hover {
                background: #b2f1ec;
                border: 1px double #80e8e0;
                color: #007a87;
            }
            &:active {
                background: #80e8e0;
                border: 1px double #80e8e0;
                color: #007a87;
            }
        }
        &__blocked_calendar {
            background: #cacccd;
            border: 1px solid #cacccd;
            color: #82888a;
            &:active,
            &:hover {
                background: #cacccd;
                border: 1px solid #cacccd;
                color: #82888a;
            }
        }
        &__hovered_start_first_possible_end {
            background: #eceeee;
            border: 1px double #eceeee;
        }
        &__hovered_start_blocked_min_nights {
            background: #eceeee;
            border: 1px double #e4e7e7;
        }
    }

    .CalendarMonth {
        background: #fff;
        text-align: center;
        vertical-align: top;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &_table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        &_verticalSpacing {
            border-collapse: separate;
        }
        &_caption {
            color: #484848;
            font-size: 18px;
            text-align: center;
            padding-top: 22px;
            padding-bottom: 37px;
            caption-side: initial;
        }
        &_caption__verticalScrollable {
            padding-top: 12px;
            padding-bottom: 7px;
        }
    }

    .CalendarMonthGrid {
        background: #fff;
        text-align: left;
        z-index: 0;
        &__animating {
            z-index: 1;
        }
        &__horizontal {
            position: absolute;
            left: 9px;
        }
        &__vertical {
            margin: 0 auto;
        }
        &__vertical_scrollable {
            margin: 0 auto;
            overflow-y: scroll;
        }
        &_month__horizontal {
            display: inline-block;
            vertical-align: top;
            min-height: 100%;
        }
        &_month__hideForAnimation {
            position: absolute;
            z-index: -1;
            opacity: 0;
            pointer-events: none;
        }
        &_month__hidden {
            visibility: hidden;
        }
    }

    .DayPickerNavigation {
        position: relative;
        z-index: 2;
        &__horizontal {
            height: 0;
        }
        &__verticalDefault {
            position: absolute;
            width: 100%;
            height: 52px;
            bottom: 0;
            left: 0;
        }
        &__verticalScrollableDefault {
            position: relative;
        }
        &_button {
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 0;
            padding: 0;
            margin: 0;
            &__default {
                border: 1px solid #e4e7e7;
                background-color: #fff;
                color: #757575;
                &:focus,
                &:hover {
                    border: 1px solid #c4c4c4;
                }
                &:active {
                    background: #f2f2f2;
                }
            }
            &__disabled {
                cursor: default;
                border: 1px solid #f2f2f2;
                &:focus,
                &:hover {
                    border: 1px solid #f2f2f2;
                }
                &:active {
                    background: 0 0;
                }
            }
            &__horizontalDefault {
                position: absolute;
                top: 18px;
                line-height: 0.78;
                border-radius: 3px;
                padding: $spacing $spacing;
                border-radius: 50%;
                background-color: #eeeef3;
            }
            &__verticalDefault {
                padding: 5px;
                background: #fff;
                box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
                position: relative;
                display: inline-block;
                text-align: center;
                height: 100%;
                width: 50%;
            }
        }
        &_leftButton__horizontalDefault {
            left: 22px;
        }
        &_rightButton__horizontalDefault {
            right: 22px;
        }
        &_nextButton__verticalDefault {
            border-left: 0;
        }
        &_nextButton__verticalScrollableDefault {
            width: 100%;
        }
        &_svg__horizontal {
            height: 19px;
            width: 19px;
            fill: #82888a;
            display: block;
        }
        &_svg__vertical {
            height: 42px;
            width: 42px;
            fill: #484848;
        }
        &_svg__disabled {
            fill: #f2f2f2;
        }
    }

    .DayPicker {
        background: #fff;
        position: relative;
        text-align: left;
        &__horizontal {
            background: #fff;
        }
        &__verticalScrollable {
            height: 100%;
        }
        &__hidden {
            visibility: hidden;
        }
        &__withBorder {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
            border-radius: 3px;
        }
        &_portal {
            &__horizontal {
                box-shadow: none;
                position: absolute;
                left: 50%;
                top: 50%;
            }
            &__vertical {
                position: initial;
            }
        }
        &_focusRegion {
            outline: 0;
        }
        &_calendarInfo__horizontal,
        &_wrapper__horizontal {
            display: inline-block;
            vertical-align: top;
        }
        &__weekHeaders {
            position: relative;
        }
        &_weekHeader {
            color: #757575;
            position: absolute;
            top: 55px;
            z-index: 2;
            text-align: left;
            &__vertical {
                left: 50%;
            }
            &__verticalScrollable {
                top: 0;
                display: table-row;
                border-bottom: 1px solid #dbdbdb;
                background: #fff;
                margin-left: 0;
                left: 0;
                width: 100%;
                text-align: center;
            }
            &_ul {
                list-style: none;
                margin: 1px 0;
                padding-left: 0;
                padding-right: 0;
                font-size: 14px;
            }
            &_li {
                display: inline-block;
                text-align: center;
            }
        }
        &_transitionContainer {
            position: relative;
            overflow: hidden;
            border-radius: 3px;
            &__horizontal {
                -webkit-transition: height 0.2s ease-in-out;
                -moz-transition: height 0.2s ease-in-out;
                transition: height 0.2s ease-in-out;
            }
            &__vertical {
                width: 100%;
            }
            &__verticalScrollable {
                padding-top: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                overflow-y: scroll;
            }
        }
    }

    .DateInput {
        height: 100%;
        margin: 0;
        padding: 0;
        background: transparent;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        border: 2px solid #eeeef3;
        &__small {
            width: 97px;
        }
        &__block {
            width: 100%;
        }
        &__disabled {
            background: #f2f2f2;
            color: #dbdbdb;
        }
        &_input {
            font-weight: 200;
            font-size: 19px;
            line-height: 24px;
            color: #484848;
            background-color: transparent;
            width: 100%;
            border: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-radius: 0;
            padding: 0;
            height: $spacing * 5;
            border-bottom: 0 !important;
            &__small {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.2px;
                padding: 7px 7px 5px;
            }
            &__regular {
                font-weight: auto;
            }
            &__readOnly {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            &__focused {
                outline: 0;
                background: transparent;
                border: 0;
                border-top: 0;
                border-right: 0;
                border-bottom: 2px solid #008489;
                border-left: 0;
            }
            &__disabled {
                background: #f2f2f2;
                font-style: italic;
            }
        }
        &_screenReaderMessage {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        &_fang {
            position: absolute;
            width: 20px;
            height: 10px;
            left: 22px;
            z-index: 2;
            display: none;
        }
        &_fangShape {
            fill: #fff;
        }
        &_fangStroke {
            stroke: #dbdbdb;
            fill: transparent;
        }
    }
}
