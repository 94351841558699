@import 'erpcore/assets/scss/main';

.chip-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 $spacing;

    &:last-child {
        margin-bottom: 0;
    }
}

.chip {
    padding: 0 $spacing;
    font: $font-label;
    line-height: 22px;
    height: 22px;
    margin: 0 $spacing $spacing 0;
    border: 1px solid rgba(getColor(mid-grey), 1);
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    transition: getTransition();

    background-image: none;
    background-color: transparent;

    &--button {
        vertical-align: middle;
        user-select: none;
        touch-action: manipulation;
        cursor: pointer;
    }

    &[data-active='1'] {
        border-color: transparent;
        color: rgba(getColor(denim), 1);
        background-color: rgba(getColor(denim), 0.1);
    }

    &[data-emphasise='1'] {
        font-weight: 700;
    }
}
