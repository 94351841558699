@import 'erpcore/assets/scss/main';

.presentation-administration {
    $_this: &;
    position: relative;
    flex: 1 1 100%;
    background: rgba(getColor(light-grey), 1);
    padding: 0;
    overflow: hidden;

    @include breakpoint($from: tablet) {
        height: 100%;
    }

    &__tab-control {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        width: 100%;
        background: #fff;
        z-index: 1;

        @include breakpoint($from: tablet) {
            display: none;
        }

        &-button {
            flex: 0 0 50%;
            padding: 12px ($spacing * 2);
            border: none;
            border-radius: 0;
            font: $font-button;
            font-size: 15px;
            font-weight: 700;
            text-transform: none;
            text-align: center;
            text-decoration: none;
            color: rgba(getColor(white), 1);
            background-image: none;
            background-color: rgba(var(--buttonColor), 1);
            vertical-align: middle;
            user-select: none;
            touch-action: manipulation;
            cursor: pointer;
            transition: getTransition();

            &[data-active='0'] {
                background-color: rgba(var(--buttonColor), 0.1);
                color: rgba(getColor(denim), 1);
            }
        }
    }

    &__content,
    &__compact {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: $spacing * 5;

        @include breakpoint($from: tablet) {
            position: absolute;
        }

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            padding: 0;
        }
    }

    &__compact {
        flex-wrap: nowrap;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
        // box-shadow: 0px 0px 25px rgba(getColor(grey), 0.3);

        @include breakpoint(tablet) {
            padding: 0;
        }

        &-columns {
            width: 100%;
            flex: 1 1 100%;
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
        }

        #{$_this}__column {
            flex: 0 0 50%;
            box-shadow: none;
            margin-right: 0;

            @media (max-width: 1200px) {
                flex-basis: 100% !important;
                max-width: 100%;
            }

            &:nth-child(1) {
                flex: 0 0 34%;
                @include breakpoint(mobile) {
                    flex: 0 0 100%;
                }
            }

            &:nth-child(2) {
                flex: 0 0 66%;
                @include breakpoint(mobile) {
                    flex: 0 0 100%;
                }
            }
        }

        &-date {
            position: relative;
            width: 100%;
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            margin: 0;
            padding: $spacing * 2 $spacing * 3;
            height: auto;
            background: #fff;

            &:after {
                content: ' ';
                display: block;
                position: absolute;
                left: $spacing * 3;
                right: 0;
                bottom: 0;
                height: 1px;
                background: rgba(getColor(grey), 0.25);
            }

            @include breakpoint(tablet) {
                //
            }

            @include breakpoint(tablet) {
                flex-wrap: wrap;
            }

            &-title {
                flex: 1 1 auto;
                font-size: 12px;
                color: rgba(getColor(grey), 1);
                padding: 0 $spacing * 2 0 0;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                white-space: nowrap;

                @include breakpoint(tablet) {
                    flex: 0 0 100%;
                    margin: 0 0 4px;
                }

                @include breakpoint(small) {
                    font-size: 10px;
                }
            }

            &-value {
                flex: 1 1 100%;
                font: $font-4;
                font-size: 16px;

                @include breakpoint(tablet) {
                    flex: 0 0 100%;
                }

                @include breakpoint(small) {
                    font-size: 14px;
                }
            }
        }
    }

    &__column {
        flex: 0 0 calc(50% - #{$spacing * 3});
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-right: $spacing * 6;
        background: #fff;
        box-shadow: 0px 0px 25px rgba(getColor(grey), 0.3);
        overflow: hidden;

        &--abstract-bg {
            background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        @include breakpoint(tablet) {
            flex: 0 0 100%;
            margin-right: 0;

            &[data-active='0'] {
                display: none;
            }
        }

        @include breakpoint(mobile) {
            // flex: 0 0 calc(50% - 4px);
            // margin-right: $spacing;
        }

        &:last-child {
            margin-right: 0;
        }

        &-title {
            margin: 0;
            padding: $spacing * 3;
            font: $font-3;
            border-bottom: 1px solid rgba(getColor(grey), 0.25);

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__section {
        $_section: &;
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: hidden;
        border-bottom: 1px solid rgba(getColor(grey), 0.25);
        transition: flex 0.3s;

        @include breakpoint(tablet) {
            flex: 0 0 auto;
        }

        &[data-closed='1'] {
            flex: 0 1 auto;
        }

        &-title {
            position: relative;
            margin: 0;
            padding: $spacing * 2 $spacing * 3;
            font: $font-3;
            font-size: 16px;
            color: rgba(getColor(denim), 1);
            text-align: left;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid rgba(getColor(grey), 0.25);

            @include breakpoint(mobile) {
                font-size: 16px;
                line-height: 1;
            }

            &-tabs {
                display: flex;
                margin-bottom: -$spacing * 2;
                margin-left: -$spacing * 3;
                margin-top: $spacing;

                @include breakpoint($to: desktop) {
                    margin-left: -$spacing * 1;
                }
            }
            &-tab {
                font: $font-4;
                background: transparent;
                border: none;
                border-bottom: 2px solid transparent;
                margin: 0 $spacing * 3;
                padding: $spacing * 1 0;
                cursor: pointer;

                @include breakpoint($to: desktop) {
                    margin: 0 $spacing * 1;
                }

                &--active {
                    color: rgba(getColor(denim), 1);
                    border-bottom-color: rgba(getColor(denim), 1);
                }

                &:hover {
                    border-bottom-color: rgba(getColor(denim), 0.1);
                }
            }

            &--button {
                background-image: none;
                background-color: transparent;
                vertical-align: middle;
                user-select: none;
                touch-action: manipulation;
                cursor: pointer;
                white-space: nowrap;
                padding-right: $spacing * 5;
            }

            &__icon {
                position: absolute;
                top: $spacing * 2;
                right: $spacing * 2;
                width: $spacing * 3;
                height: $spacing * 3;
                transform: rotate(90deg);
                transition: getTransition();

                #{$_section}[data-closed='1'] & {
                    transform: rotate(-90deg);
                }

                @include breakpoint(mobile) {
                    top: $spacing + 4px;
                }

                svg {
                    width: $spacing * 3;
                    height: $spacing * 3;
                    fill: rgba(getColor(grey), 1);
                }
            }
        }

        &-content {
            position: relative;
            padding: $spacing * 2 $spacing * 3;
            height: 100%;
            overflow: auto;
            font: $font-list;
            font-size: 13px;
            transition: opacity 0.3s getTransitionEasing('ease-out-cubic') 0.1s;

            #{$_section}[data-closed='1'] & {
                overflow: hidden;
                height: 0;
                padding: 0;
                opacity: 0;
                pointer-events: none;
            }

            &-title {
                margin: 0 0 $spacing;
                font: $font-list;
                font-size: 13px;
                font-weight: 700;

                &--uppercase {
                    text-transform: uppercase;
                }

                &--small {
                    font-size: 11px;
                    color: rgba(getColor(grey), 1);
                }
            }
        }
    }

    &__body {
        position: relative;
        display: block;
        width: 100%;

        &-item {
            margin: 0 0 $spacing * 3;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            display: block;
            margin: 0 0 $spacing * 3;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                position: relative;
                display: block;
                margin: 0 0 $spacing;
                padding: 0 0 0 $spacing * 2;
                font: $font-list;
                font-size: 13px;

                &:before {
                    content: ' ';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 3px;
                    height: 13px;
                    background: #383838;
                }
            }
        }
    }
}
