@import 'erpcore/assets/scss/main';

.presentation-questions {
    margin: 0 0 $spacing * 3;

    .collapse__content-expandable {
        padding-top: 0;
    }
    .collapse__toggle {
        top: 0;
    }

    &__question-section-title {
        text-transform: uppercase;
    }

    &__question {
        position: relative;
        margin: $spacing * 2 0;
        margin-top: $spacing * 3;

        &:first-child {
            margin-top: 0;
        }
    }
    &__answered-chip {
        padding: 0 $spacing;
        font: $font-label;
        line-height: 22px;
        height: 22px;
        margin: 0 $spacing $spacing 0;
        border-radius: 3px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        border-color: transparent;
        color: rgba(getColor(denim), 1);
        background-color: rgba(getColor(denim), 0.1);
    }
}
