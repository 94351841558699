.pdf-viewer {
    position: relative;
    width: 100%;
    height: calc(100vh - 92px);
    min-height: 500px;

    iframe {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
