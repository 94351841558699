@import 'erpcore/assets/scss/main';

.presentation-sidebar-toggle {
    position: absolute;
    margin: 0;
    border: 1px solid rgba(#fff, 0.25);
    border-radius: 0;
    background: rgba(getColor(black), 1) !important;
    z-index: 1001;

    @include breakpoint(mobile) {
        display: none !important;
    }

    .svg {
        width: $spacing * 2;
        height: $spacing * 2;
        top: -2px;
        transition: getTransition();

        svg {
            width: $spacing * 2;
            height: $spacing * 2;
            fill: #fff;
        }
    }

    &--closed {
        .svg {
            transform: scaleX(-1);
        }
    }
}