@import 'erpcore/assets/scss/main';

.presentation-cameras-mobile {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: percentage(400 / 462);
    background: rgba(getColor(black), 1);

    > div:not(.presentation-cameras) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.presentation-cameras {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.9);

    &__list {
        position: relative;
        flex: 1 1 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &-videos {
            position: relative;
            flex: 1 1 100%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .presentation-camera {
                flex: 0 0 33.33%;
                height: 25%;

                &--publisher {
                    order: 1;
                }

                &--subscriber {
                    order: 2;
                }

                &--pinned {
                    flex: 0 0 100%;
                    height: 75%;
                    order: 0;
                }
            }

            &[data-has-pinned='0'] {
                &[data-has-publisher='1'] {
                    .presentation-camera:nth-child(2) {
                        flex: 0 0 100%;
                        height: 75%;
                        order: 0;
                        .presentation-camera__controls-tooltip-pin {
                            display: none;
                        }
                    }
                }
                &[data-has-publisher='0'] {
                    .presentation-camera:nth-child(1) {
                        flex: 0 0 100%;
                        height: 75%;
                        order: 0;
                        .presentation-camera__controls-tooltip-pin {
                            display: none;
                        }
                    }
                }
            }
        }

        &-arrows {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 25%;
            // flex: 0 0 25%;
            pointer-events: none;

            &-button {
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                display: block;
                width: $spacing * 2;
                padding: 0;
                margin: 0;
                align-items: center;
                border: none;
                border-radius: 0;
                font: $font-button;
                text-transform: none;
                text-align: center;
                text-decoration: none;
                color: rgba(getColor(denim), 1);
                fill: rgba(getColor(denim), 1);
                background-image: none;
                background-color: rgba(#fff, 0.5);
                vertical-align: middle;
                user-select: none;
                touch-action: manipulation;
                cursor: pointer;
                transition: getTransition();
                pointer-events: auto;
                z-index: 9;

                &:hover {
                    background-color: rgba(#fff, 0.8);
                }

                .svg {
                    width: $spacing * 2;
                    height: $spacing * 2;

                    svg {
                        width: $spacing * 2;
                        height: $spacing * 2;
                        fill: rgba(getColor(denim), 1);
                    }
                }

                &-prev {
                    left: 0;
                }

                &-next {
                    right: 0;
                }
            }
        }

        &[data-has-publisher='1'] {
            .presentation-camera:first-child {
                //
            }
        }
    }

    &__controls {
        position: relative;
        flex: 0 0 50px;
    }
}

.presentation-camera {
    position: relative;
    flex: 0 0 100%;
    height: 100%;
    background: #000;
    border: 1px solid rgba(#fff, 0.2);
    border-top: none;

    &--hidden {
        display: none !important;
    }

    // only one element
    &:first-child:last-child {
        flex: 1 1 100%;
        height: 100%;
    }

    &__ot {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }

    .OT_subscriber {
        position: absolute;
        display: block;
        width: 100% !important;
        height: 100% !important;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;

        video {
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(getColor(black), 1);
        }
    }

    &__controls {
        position: absolute;
        width: 100%;
        height: auto;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin: 0 0 $spacing;
        z-index: 3;

        .presentation-cameras__main & {
            top: $spacing * 2;
            bottom: auto;
            left: 4px;
            right: auto;
            width: auto;
            flex-direction: column;
        }

        &-button {
            $_button: &;
            position: relative;
            flex: 0 1 auto;
            width: 36px;
            height: 28px;
            margin: 0;
            padding: 4px 8px;
            border: none;
            border-radius: 50%;
            font: $font-button;
            text-transform: none;
            text-align: center;
            text-decoration: none;
            color: rgba(getColor(white), 1);
            fill: rgba(getColor(white), 1);
            background-image: none;
            background-color: transparent;
            vertical-align: middle;
            outline: none;
            user-select: none;
            touch-action: manipulation;
            cursor: pointer;
            white-space: nowrap;
            transition: getTransition();
            overflow: hidden;
            z-index: 4;

            .presentation-cameras__main & {
                margin-bottom: $spacing;
            }

            &:hover {
                background-color: transparent;
            }

            &--non-clickable {
                cursor: auto;
            }

            &-icon {
                width: 20px;
                height: 20px;
                z-index: 5;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: rgba(getColor(white), 1);
                    filter: drop-shadow(0px 0px 0px #000);

                    #{$_button}:hover & {
                        filter: drop-shadow(0px 0px 3px #000);
                    }
                }
            }
        }
    }
}
