@import 'erpcore/assets/scss/main';

.sidebar {
    $sidebar: &;
    $sidebar_bolt_width: 64px;
    $sidebar_deck_width: 234px;
    position: relative;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: $sidebar_bolt_width;
    width: $sidebar_bolt_width;
    min-height: 100vh;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    transition: getTransition();

    &:before {
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(getColor(light-grey), 1);
        pointer-events: none;
        z-index: 1;
    }

    &:after {
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: auto;
        bottom: 0;
        width: $sidebar_bolt_width;
        height: 100%;
        background-color: rgba(getColor(black), 1);
        pointer-events: none;
        z-index: 2;
    }

    @include breakpoint(desktop) {
        // width: $spacing * 9;
        // flex: 0 0 $spacing * 9;
    }

    @include breakpoint(mobile) {
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        z-index: 99999;
        min-height: 0;
        flex: 0 0 auto;
        position: fixed;
    }

    &__content {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: $sidebar_bolt_width;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        z-index: 999;
        overflow-x: hidden;
        overflow-y: auto;
        transition: getTransition();

        @include breakpoint(mobile) {
            right: 0;
            bottom: auto;
            height: 44px;
            width: 100% !important;
        }
    }

    // region bolt

    &__bolt {
        z-index: 10;
        position: relative;
        display: flex;
        flex: 0 0 $sidebar_bolt_width;
        width: $sidebar_bolt_width;
        flex-direction: column;
        flex-wrap: nowrap;
        color: rgba(getColor(white), 1);
        fill: rgba(getColor(white), 1);
        padding: $spacing * 3 0 28px 0;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        background-color: rgba(getColor(black), 1);
        // overflow-x: hidden;

        @include breakpoint(mobile) {
            flex: 0 0 100%;
            width: 100%;
            height: 100%;
            padding: 0;
        }

        &-top,
        &-bottom {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            height: auto;
            text-align: center;
            list-style-type: none;

            &-item {
                position: relative;
                margin-top: $spacing * 1.5;

                .tooltip {
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        &-bottom {
            flex-direction: column;

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &-top {
            width: 100%;
            margin-bottom: $spacing * 2;
            flex-direction: column;

            @include breakpoint(mobile) {
                max-width: 100%;
                overflow: auto;
            }

            &-menu-icon {
                position: relative;
                margin-top: $spacing * 2;

                @media (max-height: 600px) {
                    margin-top: 12px;
                }

                @include breakpoint(mobile) {
                    display: none;
                    // display: flex;
                    margin-top: 0;
                    flex-direction: column;
                    // margin-top: -20px;
                    // flex: 0 0 88px;
                }

                &-text {
                    position: absolute;
                    top: 3px;
                    left: -100%;
                    width: 0;
                    height: 0;
                    max-width: 218px;
                    padding: 0;
                    margin: 0;
                    // margin-left: -500px;
                    text-align: left;
                    opacity: 0;
                    pointer-events: none;
                    transition: none;

                    #{$sidebar}--show-deck & {
                        left: 66px;
                        width: auto;
                        height: auto;
                        // margin-left: 0;
                        padding: $spacing $spacing * 2;
                        padding-right: 4px;
                        opacity: 1;
                        pointer-events: auto;
                        transition: opacity 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22);
                    }

                    @include breakpoint(mobile) {
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 0;
                        font-size: 10px;
                        pointer-events: auto;
                        opacity: 1;
                        margin-left: 0;

                        position: relative;
                        top: auto;
                        left: auto;
                    }
                }
            }

            &-hamburger {
                margin-bottom: $spacing * 7;

                @media (max-height: 600px) {
                    margin-bottom: $spacing * 3;
                }

                @include breakpoint(mobile) {
                    // display: none;
                    margin-bottom: 0;
                }
            }

            @include breakpoint(mobile) {
                height: 100%;
                margin-bottom: 0;
                flex-direction: row;
                padding-left: $spacing * 2;
                padding-right: $spacing * 2;
                justify-content: space-between;
            }
        }

        &-top-logo {
            margin-bottom: $spacing * 4;

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }

        &-top-profile {
            display: none;

            @include breakpoint(mobile) {
                display: block;
            }
        }
    }

    &__logo {
        width: 100%;
        max-width: $spacing * 5;
        height: auto;
        display: block;
        margin: 0 auto;

        .svg {
            display: block;
            width: 100%;
            height: auto;
        }

        svg {
            display: block;
            fill: rgba(getColor(white), 1);
            transition: getTransition();
        }

        &:hover {
            svg {
                filter: drop-shadow(1.1px 1.7px 1px rgba(92, 9, 43, 1));
            }
        }
    }

    &__button {
        width: 36px;
        height: 36px;
        display: block;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: $spacing;
        margin: 0 auto $spacing auto;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        overflow: hidden;
        transition: getTransition();
        color: rgba(getColor(black), 1);

        .sidebar__bolt-top-menu-icon.active ~ .sidebar__bolt-top-menu-icon & {
            // color: rgba(getColor(black), 0.75);
        }

        &.active {
            color: rgba(getColor(blue), 1);
        }

        &:hover {
            color: rgba(getColor(blue), 0.7);
        }

        &:last-child {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0px;
        }

        .svg {
            display: block;
            width: 20px;
            height: 20px;

            svg {
                display: block;
                fill: #616161;

                .sidebar__bolt-bottom & {
                    fill: rgba(getColor(white), 1);
                }
            }
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        .sidebar__bolt-top-menu-icon.active &,
        &.active {
            .svg {
                svg {
                    fill: rgba(getColor(blue), 1);
                }
            }
        }

        // siblings after active li element (visually reverse ordered)
        .sidebar__bolt-top-menu-icon.active ~ .sidebar__bolt-top-menu-icon & {
            .svg {
                svg {
                    fill: rgba(getColor(white), 1);
                }
            }
        }

        &--hamburger {
            @include breakpoint(mobile) {
                position: relative;
                overflow: hidden;
                background-color: transparent !important;

                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    top: -1px;
                    display: block;
                    width: 150%;
                    height: 2px;
                    background: #fff;
                    opacity: 0;
                    transition: getTransition();
                }
                &:before{
                    left: 0;
                    transform: rotate(45deg);
                    transform-origin: left top;
                }
                &:after{
                    right: 0;
                    transform: rotate(-45deg);
                    transform-origin: right top;
                }
            }

            .svg {
                opacity: 1;
                transition: getTransition();

                svg {
                    fill: rgba(getColor(white), 1);
                }
            }
        }
    }

    &__profile {
        width: $spacing * 4;
        height: $spacing * 4;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: 0;
        margin: 0 auto 0 auto;
        border: none;
        border-radius: 50%;
        background-color: rgba(getColor(white), 1);
        transition: getTransition();
        overflow: hidden;

        &:hover {
            box-shadow: 0.5px 0.8px 0.8px 0.2px rgba(85, 99, 234, 0.41);
        }

        img {
            display: block;
            overflow: hidden;
        }

        .svg {
            display: block;
            width: 100%;
            height: auto;
            fill: rgba(getColor(bright-grey), 1);
            border-radius: 50%;
            overflow: hidden;
        }
    }

    // endregion

    // region deck

    &__deck {
        position: relative;
        display: flex;
        flex: 1 1 100%;
        height: 100vh;
        flex-direction: column;
        padding: 0;
        background-color: rgba(getColor(light-grey), 1);
        overflow: hidden;
        z-index: 9;
        transition: getTransition();

        @include breakpoint(mobile) {
            display: none !important;
        }
    }

    &__mobile-overlay {
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 10;
        opacity: 0.9;
        border: none;
        width: 100vw;
        position: fixed;
        border-radius: 0;
        pointer-events: none;
        background-color: black;
        -webkit-appearance: none;
        max-width: 100% !important;
    }

    &__mobile-profile {
        top: 0;
        right: 0;
        z-index: 9;
        display: none;
        position: fixed;
        width: $spacing * 33;
        background-color: white;
        padding-top: $spacing * 7;
        box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
    }

    &__profile-list {
        margin: 0;
        font: $font-body;
        list-style-type: none;
        padding: ($spacing * 3) ($spacing * 2);

        &-item {
            &-link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-shrink: 0;
                width: 100%;
                padding: $spacing ($spacing * 2);
                text-decoration: none;
                color: rgba(getColor(black), 1);

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &--show-deck {
        flex: 0 0 #{$sidebar_bolt_width + $sidebar_deck_width};
        width: #{$sidebar_bolt_width + $sidebar_deck_width};

        @include breakpoint(mobile) {
            width: auto;
            flex: 0 0 auto;
        }

        #{$sidebar}__content {
            width: #{$sidebar_bolt_width + $sidebar_deck_width};

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        #{$sidebar}__mobile-overlay {
            @include breakpoint(mobile) {
                display: block;
                pointer-events: all;
            }
        }

        #{$sidebar}__bolt {
            // overflow-x: visible;
        }

        #{$sidebar}__deck {
            // flex: 0 0 $sidebar_deck_width;
            padding: ($spacing * 3) 0 0 $spacing;
        }

        #{$sidebar}__button {
            &--hamburger {
                &:hover {
                    background-color: rgba(255, 255, 255, 0.35);
                }

                @include breakpoint(mobile) {
                    &:before,
                    &:after {
                        opacity: 1;
                    }

                    .svg {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &--profile-opened {
        #{$sidebar}__mobile-overlay {
            @include breakpoint(mobile) {
                display: block;
                pointer-events: all;
            }
        }
        #{$sidebar}__mobile-profile {
            @include breakpoint(mobile) {
                display: block;
            }
        }
    }
}
