@import 'erpcore/assets/scss/main';

.input {
    $this: &;
    flex: 1;
    display: block;
    width: 100%;
    position: relative;
    margin: 0 $spacing * 2 0 0;

    &:last-child {
        margin-right: 0;

        @include breakpoint($to: tablet) {
            margin-bottom: 0;
        }
    }

    @include breakpoint($to: tablet) {
        margin-bottom: $spacing * 2;
    }

    &__helper-text {
        position: relative;
        bottom: -($spacing / 2);
        display: inline-block;
        width: 100%;
        font: $font-label;
        padding-left: $spacing * 2;
        margin-top: 0px;
        color: rgba(getColor(mid-grey), 1);

        &--error {
            color: rgba(getColor(tomato), 1);
        }
    }

    &__field {
        margin: 0;
        z-index: 1;
        width: 100%;
        height: $spacing * 5;
        resize: none;
        display: block;
        font: $font-form;
        padding: 0 ($spacing * 6) 0 ($spacing * 2);
        border-radius: 3px;
        position: relative;
        font-weight: normal;
        -webkit-appearance: none;
        color: rgba(getColor(black), 1);
        background-color: rgba(getColor(white), 1);
        border: 2px solid rgba(getColor(light-grey), 1);
        box-shadow: none;

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
        }

        &--textarea {
            height: auto;
            min-height: 120px;
            padding: 12px $spacing * 6 12px 18px;
        }

        &--signature {
            height: auto;
            overflow: hidden;

            canvas {
                margin-left: -16px;
            }
        }

        // browser autofill styles
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:active,
        &:-webkit-autofill:focus {
            background-color:rgba(getColor(white), 1);
            box-shadow: inset 0 0 0 1000px rgba(getColor(white), 1) !important;

            & ~ #{$this}__label {
                font-size: 11px;
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0) 51%,
                    rgba(255, 255, 255, 0) 100%
                );
                transform: translate(15px, -11px);
            }
        }

        &--view-image {
            height: auto;
            padding: 0px;
            text-align: center;
        }

        &--empty {
            line-height: 36px;
        }
    }

    &__field--smaller {
        font: $font-body;
        padding: 0 $spacing;

        @include breakpoint(tablet) {
            font: $font-form;
        }
    }

    &__field--fixed-70 {
        width: 70px;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    &__label {
        top: 0;
        left: 0;
        z-index: 2;
        padding: 5px;
        max-width: calc(100% - #{$spacing * 5});
        line-height: 1;
        font-size: 16px;
        position: absolute;
        pointer-events: none;
        transition: getTransition();
        color: rgba(getColor(grey), 1);
        transform: translate(15px, 7px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .button--action ~ & {
            max-width: calc(100% - 92px);
        }
    }

    &__action {
        min-width: $spacing * 6;
        max-height: $spacing * 5;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        line-height: 1;
        display: flex;
        cursor: pointer;
        padding: 0 $spacing * 2;
        flex-wrap: nowrap;
        position: absolute;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;

        & ~ #{$this}__action {
            right: $spacing * 4;
            padding-right: 0;
        }

        .svg {
            width: $spacing * 2;
            fill: rgba(getColor(black), 1);
        }

        &--clear {
            .svg {
                width: $spacing;
                fill: rgba(getColor(mid-grey), 1);
            }
        }
    }

    &__inner {
        display: block;
        position: relative;
    }

    &__subtext {
        display: block;
        font-size: 11px;
        padding: 8px 21px;
        color: rgba(getColor(mid-grey), 1);
    }

    &--focused {
        #{$this}__field,
        .react-select__control {
            border: 2px solid rgba(getColor(mid-grey), 1);
        }

        #{$this}__label {
            font-size: 11px;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
            );
            transform: translate(15px, -11px);
        }
    }

    &__icon {
        top: 0;
        max-height: $spacing * 5;
        left: $spacing;
        bottom: 0;
        z-index: 3;
        line-height: 1;
        display: flex;
        cursor: pointer;
        padding: 5px $spacing;
        flex-wrap: nowrap;
        position: absolute;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        pointer-events: none;

        .svg {
            fill: rgba(getColor(dark-grey), 1);
        }

        &--right {
            & ~ #{$this}__action {
                right: $spacing * 4;
                padding-right: 0;
            }
        }
    }

    &--icon-left,
    &--icon-left.input--disabled {
        #{$this}__field,
        .react-select__control {
            padding-left: $spacing * 6;
        }
        .react-select__input {
            padding-left: 30px;
        }
        .location__dropdown-btn {
            padding-left: $spacing * 6;
        }
        #{$this}__label {
            left: $spacing * 4;
        }
    }

    &--icon-right {
        #{$this}__icon {
            left: auto;
            right: $spacing;
        }
    }

    &--active {
        #{$this}__label {
            max-width: calc(100% - 18px);
            font-size: 11px;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
            );
            transform: translate(15px, -11px);
        }

        .button--action ~ #{$this}__label {
            max-width: calc(100% - #{$spacing * 10});
        }

        &#{$this}--disabled {
            #{$this}__label {
                background: none;
            }
        }
    }

    &--disabled {
        opacity: 0.8;

        #{$this}__field {
            background-color:rgba(getColor(light-grey), 1);
            border: 2px solidrgba(getColor(light-grey), 1);
            padding: 0 ($spacing * 2);

            &.input__field--smaller {
                padding: 0 $spacing;

                @include breakpoint(tablet) {
                    padding: 0 $spacing * 2;
                }
            }

            &--textarea {
                padding: 12px $spacing * 6 12px 18px;
            }
        }
    }

    &--error {
        #{$this}__field {
            border: 2px solid rgba(getColor(tomato), 1);
        }
    }

    &--required {
        &:after {
            content: '*';
            position: absolute;
            right: -11px;
            top: 0px;
            font: $font-form;
            color: rgba(getColor(grey), 1);
        }
    }
}
