@import 'erpcore/assets/scss/main';

.presentation-chat {
    position: relative;
    $root: &;
    background: rgba(getColor(white), 1);
    color: rgba(getColor(dark-grey), 1);
    left: $spacing * -3;
    width: calc(100% + 48px);
    margin-top: $spacing * -3;
    margin-bottom: $spacing * 3;

    @include breakpoint(mobile) {
        left: 0;
        width: 100%;
    }

    &--bound-to-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .presentation-intro & {
        background: none;
    }

    &__messages {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        padding: 0 $spacing * 3;
        padding-top: $spacing * 3;

        #{$root}--bound-to-container & {
            flex: 1 1 100%;
            max-height: none;
        }
    }

    &__message {
        $message: &;
        font-size: 13px;
        line-height: 16px;
        margin: $spacing * 2 0;

        &:first-child {
            margin-top: 0;
        }

        &-author {
            font-weight: bold;

            span:first-child {
                display: inline-block;
                margin-right: $spacing * 1.5;
            }
        }

        &--own {
            #{$message}-author {
                color: rgba(getColor(blue), 1);
            }
        }
    }

    &__form {
        #{$root}--bound-to-container & {
            flex: 0 0 auto;
        }

        @include breakpoint(mobile) {
            margin-top: $spacing * 2;
        }

        @include breakpoint($to: tablet) {
            .form__row {
                display: flex;
            }
        }
    }

    .input {
        margin: 0;
        &__field {
            border-radius: 0px;
        }
    }
    .button {
        border-radius: 0px;
    }
}
