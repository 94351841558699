@import 'erpcore/assets/scss/main';

.presentation-prospect-answers {
    margin: 0 0 $spacing * 3;

    &__item {
        margin: 0 0 $spacing;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
