@import 'erpcore/assets/scss/main';

.presentation-camera-publisher {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .OT_publisher {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid rgba(getColor(black), 1);

        .OT_name {
            right: 10px;
        }
    }

    &__controls {
        position: absolute;
        width: 100%;
        height: auto;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin: 0 0 $spacing;
        z-index: 3;

        display: none !important;

        &-button {
            $_button: &;
            position: relative;
            flex: 0 1 auto;
            width: 26px;
            height: 26px;
            margin: 0 $spacing 0;
            padding: 4px;
            border: none;
            border-radius: 50%;
            font: $font-button;
            text-transform: none;
            text-align: center;
            text-decoration: none;
            color: rgba(getColor(white), 1);
            fill: rgba(getColor(white), 1);
            background-image: none;
            background-color: transparent;
            vertical-align: middle;
            outline: none;
            user-select: none;
            touch-action: manipulation;
            cursor: pointer;
            white-space: nowrap;
            transition: getTransition();
            overflow: hidden;
            z-index: 4;

            &:hover {
                background-color: transparent;
            }

            &:before {
                position: absolute;
                content: ' ';
                display: block;
                width: 0;
                top: 0;
                right: 0;
                border-top: 2px solid red;
                transform: rotate(-45deg);
                transform-origin: right top;
                opacity: 0;
                transition: getTransition();
                z-index: 6;
            }

            &[data-active='0'] {
                &:before {
                    width: 150%;
                    opacity: 1;
                }
            }

            &-icon {
                width: 18px;
                height: 18px;
                z-index: 5;

                svg {
                    width: 18px;
                    height: 18px;
                    fill: rgba(getColor(white), 1);
                    filter: drop-shadow(0px 0px 0px #000);

                    #{$_button}:hover & {
                        filter: drop-shadow(0px 0px 3px #000);
                    }
                }
            }
        }
    }
}

.presentation-camera-publisher-takeover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;

    &__text {
        flex: 0 0 100%;
        margin: 0 0 4px;
        font-size: 8px;
        line-height: 1.25;
        color: #fff;
    }

    &__button {
        flex: 0 0 auto;
        border-radius: 0;
    }
}