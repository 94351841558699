@import 'erpcore/assets/scss/main';

.presentation-raised-hands {
    position: relative;
    border-bottom: 1px solid rgba(getColor(mid-grey), 1);
    padding-bottom: $spacing;
    margin-bottom: $spacing * 2;
    $root: &;

    &__hand {
        position: relative;
        $message: &;
        font-size: 13px;
        line-height: 16px;
        margin: $spacing * 3 0;

        &:first-child {
            margin-top: 0;
        }

        &-author {
            font-weight: bold;
        }
        &-action {
            position: absolute;
            right: 0;
            top: -4px;

            .button--small .button__icon {
                width: 10px;
                height: 10px;
            }
        }
    }
}
