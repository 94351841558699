@import 'erpcore/assets/scss/main';

.presentation-expired {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 985px;
        max-width: 100%;
        margin: 0 auto;
        padding: $spacing * 3;
        text-align: center;
    }

    &__logo {
        width: 140px;
        margin: 0 auto 120px;

        .main--presentation-admin & {
            margin-bottom: $spacing * 3;
        }

        @include breakpoint(tablet) {
            margin-bottom: $spacing * 3;
        }

        img {
            width: 140px;
            height: auto;
        }
    }

    &__title {
        margin: 0 auto $spacing * 3;
        font-size: 22px;
        line-height: 24px;

        @include breakpoint(tablet) {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &__content {
        margin: auto auto $spacing * 9;
        max-width: 800px;

        @include breakpoint(tablet) {
            margin-bottom: $spacing * 3;
        }

        p {
            font-size: 16px;
            line-height: 26px;

            @include breakpoint(tablet) {
                font-size: 14px;
                line-height: 1.3;
            }
        }
    }

    .projects__session {
        width: 750px;
        margin-bottom: 0 !important;

        &-item-value {
            display: block;
        }
        &-item-subvalue {
            font-weight: bold;
            margin-top: $spacing;
            display: block;
        }
    }
}
