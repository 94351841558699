@import 'erpcore/assets/scss/main';

.presentation-start {
    overflow: auto;
    //padding: $spacing * 2 $spacing * 4;

    @include breakpoint(tablet) {
        //padding: $spacing * 2;
    }

    &__title {
        font: $font-4;
        margin: 0 0 $spacing * 2;
    }

    &__list {
        display: block;
        width: 100%;
        max-width: 420px;
        margin: 0 0 $spacing * 2;
        padding: 0;
        counter-reset: introListCounter;

        li {
            position: relative;
            display: block;
            margin: 0 0 $spacing * 2;
            padding: 0 0 0 $spacing * 4;
            font: $font-label;
            font-size: 12px;
            line-height: 16px;

            @include breakpoint(mobile) {
                font-size: 11px;
                line-height: 1.7;
                padding-left: $spacing * 3;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                counter-increment: introListCounter;
                content: counter(introListCounter);
                position: absolute;
                top: -2px;
                left: 0;
                width: 20px;
                height: 20px;
                font-size: 10px;
                line-height: 21px;
                font-weight: 700;
                text-align: center;
                background: rgba(getColor(denim), 1);
                color: #fff;
                border-radius: 50%;
            }
        }

        .button--link {
            display: inline;
            font: $font-label;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            height: 16px;
            vertical-align: baseline;
            margin: 0;
        }
    }

    &__button {
        width: 100%;
    }
}