@import 'erpcore/assets/scss/main';

.projects {
    padding: $spacing * 4;
    width: 100%;
    text-align: center;
    max-width: 1500px;
    margin: 0px auto;

    @include breakpoint(mobile) {
        padding: $spacing * 3;
    }

    &__logo {
        display: block;
        width: 140px;
        margin: 0px auto;
    }

    &__logo-wrapper {
        min-height: 70px;
    }

    &__developer-logo {
        width: 200px;
        margin: 0px auto;
    }

    &__subtitle {
        margin: $spacing * 6 0;
        font-weight: bold;
        line-height: 2;

        &--thin {
            font-weight: 500;
        }

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__item-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: $spacing * 3 0;
    }

    &__sign-in {
        max-width: 984px;
        margin: 0px auto;
        min-height: 270px;
        padding: 129px 260px;
        border: 1px solid rgba(getColor(dark-grey), 1);
        text-align: left;

        @include breakpoint(mobile) {
            padding: $spacing * 3;
        }
    }

    &__item {
        $item: &;
        text-align: center;
        position: relative;
        border: 1px solid rgba(getColor(dark-grey), 1);
        color: rgba(getColor(dark-grey), 1);
        flex: 0 1 calc(30% - 1%);
        margin: 1%;
        margin-bottom: 4%;
        cursor: pointer;
        padding-bottom: $spacing * 4;
        transition: getTransition();

        &--no-link {
            cursor: unset;
        }

        @include breakpoint(mobile) {
            flex: 0 0 calc(50% - 16px);
            margin: $spacing $spacing $spacing * 3;
        }

        @include breakpoint(small) {
            flex: 0 0 100%;
            margin: 0 0 $spacing * 4;
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(dark-grey), 1);

            #{$item}-action {
                color: rgba(getColor(white), 1);
                background: rgba(getColor(dark-grey), 1);
            }

            .projects__item-image {
                // transform: scale(1.01);
            }
        }

        &-image {
            flex-basis: 100%;
            width: 100%;
            padding-top: 70%;
            background-color: rgba(getColor(light-grey), 1);
            background-size: cover;
            transition: getTransition();
        }
        &-content {
            flex-basis: 100%;
            width: 100%;
            padding: $spacing * 2;
        }
        &-location {
            display: block;
            text-transform: uppercase;
            // font: $font-list;
            font-size: 11px;
            line-height: 45px;
            color: #000000;
        }
        &-name {
            font-size: 40px;
            line-height: 45px;
            font-family: $font_family_butler;
            font-weight: 700;
            display: block;
            margin: 0;
            text-transform: uppercase;

            @include breakpoint(mobile) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        &-type {
            display: block;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 45px;
        }
        &-action {
            position: absolute;
            display: inline-block;
            text-transform: uppercase;
            padding: $spacing $spacing * 3;
            border: 1px solid rgba(getColor(dark-grey), 1);
            border-radius: 8px;
            margin: 0px auto;
            background: rgba(getColor(white), 1);
            bottom: -18px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 14px;
            transition: getTransition();
            letter-spacing: 0.8px;
        }
    }

    &__session {
        display: flex;
        max-width: 100%;
        width: 1074px;
        margin: 0 auto $spacing * 6;
        padding: $spacing * 4 $spacing * 5;
        background: #f8f8f8;
        text-align: left;

        @include breakpoint(small) {
            padding: $spacing * 2;
            flex-wrap: wrap;
        }

        &-item {
            flex: 1 1 33%;
            margin: 0;
            padding-right: $spacing * 2;

            @include breakpoint(mobile) {
                flex: 1 1 100%;
                margin: 0 0 $spacing * 2;
                padding-right: 0;

                &:last-child {
                    margin: 0;
                }
            }

            &:last-child {
                padding-right: 0;
            }

            &-title {
                display: block;
                width: 100%;
                margin: 0 0 $spacing;
                font-size: 12px;
                color: getColor(grey);
                padding: 0 $spacing 0 0;
                text-transform: uppercase;
                letter-spacing: 0.5px;

                @include breakpoint(mobile) {
                    margin: 0 0 4px;
                    font-size: 10px;
                    line-height: 10px;
                }
            }

            &-value {
                display: block;
                margin: 0 0 $spacing * 2;
                font: $font-4;
                font-size: 22px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include breakpoint(mobile) {
                    font-size: 16px;
                    line-height: 1;
                }

                &--smaller {
                    font-size: 18px;

                    @include breakpoint(mobile) {
                        font-size: 14px;
                    }
                }
            }

            &-user {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                &-logo {
                    flex: 0 0 54px;
                    margin-right: $spacing;
                    border-radius: 50%;
                    overflow: hidden;

                    &-image {
                        display: block;
                        width: 100%;
                        height: auto;
                        margin: 0;
                    }
                }

                &-data {
                    flex: 1 1 100%;

                    &-title {
                        margin: 0 0 $spacing;
                        font: $font-4;
                        font-size: 18px;

                        @include breakpoint(mobile) {
                            margin: 0 0 4px;
                            font-size: 14px;
                        }
                    }

                    &-email,
                    &-telephone {
                        margin: 0 0 4px;
                        font: $font-label;
                        font-size: 12px;

                        @include breakpoint(mobile) {
                            font-size: 11px;
                            line-height: 1.2;
                            word-break: break-word;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &__documents {
        padding: $spacing * 6 $spacing * 6 0 $spacing * 6;

        @include breakpoint(mobile) {
            padding: $spacing * 3;
        }
    }

    &__document {
        padding: $spacing * 2;
        background-color: rgba(getColor(light-grey), 0.7);
        margin-bottom: $spacing * 2;
        display: flex;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;
        }

        &-extension {
            color: white;
            position: absolute;
            left: 0;
            bottom: 0px;
            font: $font-label;
            text-transform: uppercase;
            width: 100%;
            color: getColor(dark-grey);
            text-align: center;
        }

        &-icon {
            width: 45px;
            height: 50px;
            margin-right: $spacing * 2;
            position: relative;

            svg {
                width: 45px;
                height: auto;

                .st0 {
                    fill: rgba(getColor(mid-grey), 1);
                }
                .st2 {
                    fill: rgba(getColor(grey), 1);
                }
                .st1 {
                    fill: #fff;
                }
            }
        }

        &-actions {
            align-self: center;
            a {
                font: $font-label;
            }
        }

        &-separator {
            display: inline-block;
            margin: 0 $spacing;
            font-size: 12px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
