@import 'erpcore/assets/scss/main';

.prospect-list {
    display: block;
    width: calc(100% + #{$spacing * 3 * 2});
    margin-left: -#{$spacing * 3};
    margin-top: -#{$spacing * 2};
    margin-bottom: -#{$spacing * 2};

    &__item {
        display: flex;
        padding: $spacing * 2 $spacing * 3;
        border-bottom: 1px solid rgba(getColor(grey), 0.25);
        justify-content: space-between;

        @include breakpoint(mobile) {
            padding: $spacing $spacing * 2;
        }

        &-name {
            position: relative;
            display: block;
            margin: 0;
            padding: 0 $spacing 0 $spacing * 3;
            font: $font-body;
            font-size: 15px;

            @include breakpoint(mobile) {
                font-size: 14px;
                line-height: 1;
            }

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 3px;
                width: 3px;
                height: 13px;
                background: #383838;

                @include breakpoint(mobile) {
                    top: 0;
                }
            }
        }

        .status-circle {
            width: $spacing * 2;
            height: $spacing * 2;
            margin-top: 1px;

            @include breakpoint(mobile) {
                width: 12px;
                height: 12px;
                margin-top: 2px;
            }
        }
    }
}
