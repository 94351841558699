@import 'erpcore/assets/scss/main';

.presentation-camera-test {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: percentage(295 / 435);

    &__publisher {
        position: absolute;
        width: 100% !important;
        height: auto !important;
        top: 0;
        bottom: 50px;
        left: 0;
        right: 0;

        .OT_publisher {
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .OT_name {
                right: 10px;
            }
        }
    }

    &__controls {
        position: absolute;
        width: 100%;
        height: 50px;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: #fff;
        z-index: 3;

        &-button {
            $_button: &;
            position: relative;
            flex: 0 1 auto;
            width: 42px;
            height: 30px;
            margin: 0;
            padding: 4px $spacing;
            border: none;
            border-radius: 50%;
            font: $font-button;
            text-transform: none;
            text-align: center;
            text-decoration: none;
            color: rgba(getColor(denim), 1);
            fill: rgba(getColor(denim), 1);
            background-image: none;
            background-color: transparent;
            vertical-align: middle;
            outline: none;
            user-select: none;
            touch-action: manipulation;
            cursor: pointer;
            white-space: nowrap;
            transition: getTransition();
            overflow: hidden;
            z-index: 4;

            &:hover {
                background-color: transparent;
            }

            &-icon {
                width: 22px;
                height: 22px;
                z-index: 5;

                svg {
                    width: 22px;
                    height: 22px;
                    fill: rgba(getColor(denim), 1);
                }
            }
        }
    }
}

.presentation-camera-publisher-takeover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;

    &__text {
        flex: 0 0 100%;
        margin: 0 0 4px;
        font-size: 9px;
        line-height: 1.3;
        color: #fff;
    }

    &__button {
        flex: 0 0 auto;
        border-radius: 0;
    }
}