@import 'erpcore/assets/scss/main';

.presentation-loading {
    display: flex;
    width: 100%;
    height: 100vh;
    background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}