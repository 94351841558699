@import 'erpcore/assets/scss/main';

.presentation-screen-publisher {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 $spacing * 3;
    transition: getTransition();

    /*
    .presentation[data-phase='intro'] & {
        width: 290px;
        max-width: calc(100% - #{$spacing * 4});
        margin-left: $spacing * 4;

        @include breakpoint(mobile) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
        }
    }
    */

    .presentation--admin[data-phase="in-progress"] & {
        @include breakpoint(mobile) {
            width: 300px;
            max-width: 100%;
            margin: 0;
        }
    }

    &__title {
        margin: 0 0 $spacing * 2;
        size: 20px;
        line-height: 1;

        .presentation[data-phase='intro'] & {
            display: none;
        }

        @include breakpoint(mobile) {
            font-size: 15px;
        }

        .presentation--admin[data-phase="in-progress"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    &__info {
        margin: 0 0 $spacing * 3;

        .presentation[data-phase='intro'] & {
            display: none;
        }

        .presentation--admin[data-phase="in-progress"] & {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        &-item {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
        }
    }

    &__screen {
        position: relative;
        width: 100%;
        text-align: center;
        overflow: hidden;

        &-border {
            position: relative;
            border: $spacing solid #e7e7e7;
            border-bottom: none;
            transition: getTransition();
            background: rgba(getColor(grey), 1);
            background-image: linear-gradient(
                0deg,
                #767676 16.67%,
                #b6b6b6 16.67%,
                #b6b6b6 50%,
                #767676 50%,
                #767676 66.67%,
                #b6b6b6 66.67%,
                #b6b6b6 100%
            );
            background-size: 6px 6px;
            z-index: 1;

            &[data-sidebar='1'] {
                padding-left: $spacing * 6;

                .presentation[data-phase='intro'] & {
                    padding-left: 0;
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: percentage(9/16);
            background-color: rgba(getColor(black), 1);
            background-image: linear-gradient(
                131deg,
                #000000 10%,
                #303030 10%,
                #303030 50%,
                #000000 50%,
                #000000 60%,
                #303030 60%,
                #303030 100%
            );
            background-size: 6.63px 7.62px;

            .OT_video-poster {
                display: none !important;
            }

            video.OT_video-element {
                display: block !important;
            }

            &-view {
                position: absolute;
                display: block;
                width: 100%;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;

                video {
                    background-color: rgba(getColor(black), 1);
                    background-image: linear-gradient(
                        131deg,
                        #000000 10%,
                        #303030 10%,
                        #303030 50%,
                        #000000 50%,
                        #000000 60%,
                        #303030 60%,
                        #303030 100%
                    );
                    background-size: 6.63px 7.62px;
                }
            }
        }

        &-monitor {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: -57% 0 0;
            z-index: 0;

            .svg {
                display: block;
                width: 100%;
                height: auto;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-start {
            &--action-start {
                .presentation[data-phase='intro'] & {
                    display: none;
                }
            }
        }

        &-message {
            margin: $spacing 0;
            font-size: 10px;
            line-height: 1.3;
        }
    }
}
