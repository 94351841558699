@import 'erpcore/assets/scss/main';

.presentation-controls {
    $_this: &;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 $spacing;
    background: #fff;

    &[data-theme='dark'] {
        margin-top: -1px;
        background: #000;
        border-top: 1px solid #3e3e3e;
        border-bottom: 1px solid #3e3e3e;
    }

    .presentation-intro & {
        background: rgba(getColor(light-grey), 1);
    }

    &__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .tooltip {
        flex: 1 1 auto;
    }

    &__button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        width: auto;
        height: 22px;
        margin: 0;
        padding: 4px 6px;
        border: none;
        border-radius: 0;
        font: $font-button;
        text-transform: none;
        text-align: center;
        text-decoration: none;
        color: rgba(getColor(denim), 1);
        fill: rgba(getColor(denim), 1);
        background-image: none;
        background-color: transparent;
        vertical-align: middle;
        outline: none;
        user-select: none;
        touch-action: manipulation;
        cursor: pointer;
        white-space: nowrap;
        transition: getTransition();
        z-index: 4;

        #{$_this}[data-theme='dark'] & {
            color: #fff;
            fill: #fff;
        }

        &:hover {
            background-color: transparent;
            opacity: 0.8;
        }

        &--non-clickable {
            cursor: auto;
        }

        &-icon {
            flex: 0 0 18px;
            width: 18px;
            height: 18px;
            z-index: 5;

            svg {
                width: 18px;
                height: 18px;
                fill: rgba(getColor(denim), 1);

                #{$_this}[data-theme='dark'] & {
                    fill: #fff;
                }
            }
        }

        &-annotation {
            flex: 0 0 18px;
            display: block;
            width: 18px;
            height: 18px;
            font-size: 11px;
            font-weight: 700;
            line-height: 18px;
            background: rgba(getColor(gray), 0.25);
            color: rgba(getColor(dark-grey), 1);
            border-radius: 50%;
            text-align: center;

            &--chat {
            }

            &--raise-hand {
                background: rgba(getColor(apple), 0.25);
                color: rgba(getColor(apple), 1);
            }

            &--participants {
                background: rgba(getColor(blue), 0.25);
                color: rgba(getColor(blue), 1);
            }

            #{$_this}[data-theme='dark'] & {
                background: rgba(#fff, 0.25);
                color: rgba(#fff, 0.8);
            }
        }

        &:before {
            z-index: -1;
            position: absolute;
            content: '+';
            display: block;
            line-height: 10px;
            border-radius: 100%;
            margin-right: 5px;
            width: 10px;
            height: 10px;
            background: rgba(getColor(blue), 1);
            color: rgba(getColor(white), 1);
            opacity: 0;
        }

        &.pop {
            animation-name: wave-animation;
            animation-duration: 2s;
            animation-iteration-count: 1;
            transform-origin: 70% 70%;

            &:before {
                animation-name: wave-animation-buble;
                animation-duration: 2s;
                animation-iteration-count: 1;
                transform-origin: 70% 70%;
            }
        }
    }

    &__divider {
        display: block;
        height: 22px;
        width: 1px;
        margin: 0 8px 0;
        background: rgba(getColor(grey), 0.4);
    }
}

@keyframes wave-animation {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1.5);
    }
    20% {
        transform: scale(1.5);
    }
    30% {
        transform: scale(1.5);
    }
    40% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes wave-animation-buble {
    0% {
        opacity: 1;
        transform: rotate(0deg) scale(1) translateY(0);
        right: 0px;
    }
    20% {
        right: 2px;
    }
    40% {
        right: -3px;
    }
    60% {
        right: 4px;
    }
    80% {
        right: -5px;
        opacity: 0;
    }
    100% {
        right: 6px;
        opacity: 0;
        transform: rotate(0deg) scale(2) translateY(-30px);
    }
}
