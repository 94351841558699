@import 'erpcore/assets/scss/main';

.presentation-join {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-image: url(~erpcore/assets/images/abstract-bg-white.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;

    &__permission-notice {
        width: 100%;
        height: 100px;
        padding: $spacing * 2;
        top: 0;
        left: 0;
        right: 0;
        background: #3b78e7;
        color: #fff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &[data-denied='1'] {
            background: #c10000;
        }

        p {
            margin: 0;
            color: #fff;
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 870px;
        max-width: 100%;
        margin: 0 auto;
        padding: $spacing * 3;
        text-align: center;
    }

    &__logo {
        width: 140px;
        margin: 0 auto 120px;

        img {
            width: 140px;
            height: auto;
        }

        .main--presentation-admin & {
            margin-bottom: $spacing * 3;
        }

        @include breakpoint(tablet) {
            margin-bottom: $spacing * 3;
        }
    }

    &__title {
        margin: 0 auto $spacing * 3;
        font-size: 22px;
        line-height: 24px;

        @include breakpoint(tablet) {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &__content {
        margin: 0 0 $spacing * 9;

        @include breakpoint(tablet) {
            margin-bottom: $spacing * 3;
        }

        p {
            font-size: 16px;
            line-height: 26px;

            @include breakpoint(tablet) {
                font-size: 14px;
                line-height: 1.3;
            }
        }
    }

    &__setup {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: center;
        margin: 0 0 $spacing * 7;

        @include breakpoint(tablet) {
            margin-bottom: $spacing * 3;
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        &-camera {
            flex: 0 0 50%;
            height: 295px;
            position: relative;

            @media (max-width: 600px) {
                height: auto;
                flex: unset;
                width: calc(100% + 48px);
                margin-left: -24px;
            }
        }

        &-form {
            flex: 0 0 50%;
            padding: $spacing * 3 $spacing * 3 0;

            @media (max-width: 600px) {
                flex: 0 0 100%;
                padding: $spacing * 3 0;
                width: 100%;
            }
        }
    }

    .projects__session {
        width: 870px;
        margin-bottom: 0 !important;
    }

    .input__label--active {
        opacity: 0;
        pointer-events: none;
    }

    &__errors {
        width: 100%;
        margin: 0 0 $spacing * 3;
    }
}
