@import 'erpcore/assets/scss/main';

.main-navigation {
    $root: &;
    width: 100%;
    padding: 0 $spacing ($spacing * 3) $spacing;

    .page-content__side-menu & {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    &__menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .page-content__side-menu & {
            padding: 6px 0;

            @include breakpoint(mobile) {
                padding-left: $spacing * 3;
                display: flex;
                overflow: auto;
            }
        }

        &-item {
            display: block;
            width: 100%;
            margin: 0;
            padding: $spacing 0;

            .page-content__side-menu & {
                @include breakpoint(mobile) {
                    width: auto;
                    flex: 0 0 auto;
                    padding: 0;
                    word-break: keep-all;
                    display: flex;
                }
            }

            &--open {
                #{$root}__submenu {
                    height: auto;
                }

                #{$root}__menu-link-badge {
                    transform: rotate(180deg);

                    .page-content__side-menu & {
                        @include breakpoint(mobile) {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            &--bottom {
                position: absolute;
                bottom: $spacing * 2;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;
            width: 100%;
            font: $font-body;
            text-align: left;
            color: rgba(getColor(black), 1);
            margin: 0;
            padding: $spacing ($spacing * 2);
            border: none;
            border-radius: 3px;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;

            .page-content__side-menu & {
                padding: 2px ($spacing * 2);
                font-size: 14px;

                @include breakpoint(mobile) {
                    width: auto;
                    flex: 0 0 auto;
                    padding-left: 0;
                    padding-right: 0;
                    margin-right: $spacing * 3;
                    // color: rgba(#fff, 0.5);

                    &:hover {
                        // color: rgba(#fff, 1);
                    }
                }
            }

            &:hover {
                text-decoration: none;
            }

            &-badge {
                font: $font-label;
                background-color: transparent;
                transition: getTransition();

                .page-content__side-menu & {
                    margin-left: $spacing;

                    @include breakpoint(mobile) {
                        margin-left: 2px;
                    }
                }

                .svg {
                    display: block;
                    width: $spacing;
                    height: $spacing;
                    fill: rgba(getColor(black), 1);

                    .page-content__side-menu & {
                        @include breakpoint(mobile) {
                            // fill: rgba(#fff, 1);
                        }
                    }
                }
            }

            &--active {
                color: rgba(getColor(blue), 1);

                .page-content__side-menu & {
                    @include breakpoint(mobile) {
                        // color: rgba(#fff, 1);
                    }
                }
            }
        }
    }

    &__submenu {
        height: 0px;
        list-style-type: none;
        padding: 0px;
        overflow: hidden;

        .page-content__side-menu & {
            @include breakpoint(mobile) {
                display: none;
                width: auto;
                flex: 0 0 auto;
                padding: 0;
            }
        }

        .main-navigation__menu-item--open & {
            .page-content__side-menu & {
                @include breakpoint(mobile) {
                    display: flex;
                }
            }
        }

        &-item {
            padding: 0;

            .page-content__side-menu & {
                @include breakpoint(mobile) {
                    width: auto;
                    flex: 0 0 auto;
                    padding: 0;
                    word-break: keep-all;
                }
            }
        }

        &-link {
            display: block;
            padding: $spacing ($spacing * 2) $spacing ($spacing * 4);
            color: rgba(getColor(black), 1);
            text-decoration: none;
            cursor: pointer;

            .page-content__side-menu & {
                font-size: 14px;

                @include breakpoint(mobile) {
                    padding: 2px 0;
                    margin-right: $spacing * 3;
                    font-size: 12px;
                    opacity: 0.75;
                    // color: rgba(#fff, 0.35);

                    &:hover {
                        // color: rgba(#fff, 1);
                    }
                }
            }

            &:hover {
                text-decoration: none;
            }

            &--active {
                color: rgba(getColor(blue), 1);
                background-color: rgba(getColor(light-grey), 1);

                .page-content__side-menu & {
                    @include breakpoint(mobile) {
                        // color: rgba(#fff, 1);
                        opacity: 1;
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &__logo {
        margin: $spacing $spacing * 2 $spacing * 5 $spacing * 2;
        height: 54px;

        svg {
            width: 100px;
            height: 100%;
        }
    }
}
