.embed-block {
    $_this: &;
    position: relative;
    display: block;
    width: 100%;

    &--fullscreen {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 65%;

        #{$_this}--fullscreen & {
            position: absolute;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-bottom: 0;
        }
    }

    iframe {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
