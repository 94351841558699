.youtube-video-block {
    position: relative;
    display: block;
    width: 100%;

    &--fullscreen {
        position: absolute;
        display: block;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
