@import 'erpcore/assets/scss/main';

.status-circle {
    $root: &;
    display: inline-block;
    width: $spacing;
    height: $spacing;
    line-height: $spacing;
    padding: 0;
    border-radius: 50%;
    background: rgba(getColor(sun), 1);
    color: rgba(getColor(white), 1);

    &--new {
        background: rgba(getColor(sky), 1);
    }

    &--voided,
    &--declined,
    &--rejected {
        background: rgba(getColor(tomato), 1);
    }

    &--pending,
    &--contract_pending,
    &--draft {
        background: rgba(getColor(sun), 1);
    }

    &--accepted,
    &--fulfilled,
    &--approved,
    &--paid {
        background: rgba(getColor(apple), 1);
    }

    &--special,
    &--payment_pending {
        background: rgba(getColor(lavander), 1);
    }

    &--canceled {
        background: getColor(silver-grey);
    }

    &--admin {
        background: rgba(getColor(black), 1);
    }
}
